import React, { useEffect, useState } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Breadcrumbs, Link as MUILink,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import PeopleIcon from "@mui/icons-material/People";
import { Link } from "react-router-dom";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../axiosInstance";
import { useSelector } from "react-redux";

const Evaluation = () => {
  const { user } = useSelector((state) => state.user);
  const [exams, setExams] = useState([]);
  const [filteredExams, setFilteredExams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Get All Exam Data
  const getAllSubjectiveExams = async () => {
    try {
      const response = await axios.get(
        `/api/v1/reports/subjective/admin-get-all-subjectiveReport`
      );
      if (response?.data?.success === true) {
        // Transform the data
        const transformedData = response.data.data.map((exam, index) => {
          const report = exam.reportIds[0];
          return {
            ...exam,
            index: index + 1,
            examName: report.examName,
            category: report.category,
            totalMarks: report.totalMarks,
            passingMarks: report.passingMarks,
            userId: report.userId,
            negativeMarking: report.negativeMarking,
            examId: report.examId,
            instituteId: report.instituteId,
          };
        });
        setExams(transformedData);
        setFilteredExams(transformedData);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllSubjectiveExams();
  }, []);

  // Filter exams based on search query and selected category
  useEffect(() => {
    let filtered = exams;

    if (searchQuery) {
      filtered = filtered.filter((exam) =>
        exam.examName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter((exam) => exam.category === selectedCategory);
    }

    setFilteredExams(filtered);
  }, [searchQuery, selectedCategory, exams]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const columns = [
    {
      field: "index",
      headerName: "No.",
      width: 70,
    },
    {
      field: "Users List",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={`/admin/subjective/students-details/${value?.row?.examId}`}>
            <IconButton aria-label="view">
              <PeopleIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Link>
        </Box>
      ),
    },
    // {
    //   field: "instituteId",
    //   headerName: "Institute Id",
    //   width: 130,
    //   editable: true,
    // },
    {
      field: "examName",
      headerName: "Exam Name",
      width: 270,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 270,
      editable: true,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 180,
      editable: true,
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 180,
      editable: true,
    },
    {
      field: "negativeMarking",
      headerName: "Negative Marks",
      width: 180,
      editable: true,
    },
  ];
   // Conditionally add the instituteId column if user.role === 1
   if (user && user.role === 1) {
    columns.splice(2, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    });
  }

  return (
    <div>
      <PageTitle 
      title="Exams Copy List - View & Manage Answer Copies" 
      description="Access and manage all subjective exam copies submitted by students. Review answers, evaluate performance, and generate reports for efficient assessment."
      keywords="test series, subjective exam copies, answer copy list, evaluate subjective tests, student answer sheets, exam assessment, subjective test review, online exam copies"
      />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Subjective Exams List
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/subjective/exams"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Subjective Exams
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>Evaluation</Typography>
        </Breadcrumbs>
        <Box
          style={{
            width: "auto",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        >
          {/* Search Input */}
          <Box sx={{ mb: 2, mt: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Exam Name"
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </IconButton>
                ),
              }}
              sx={{
                mb: 3,
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ mb: 3, mt: 3 }}> 
            <FormControl 
             fullWidth
              sx={{
                mb: 3,
                bgcolor: "white",
                borderRadius: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}>
              <InputLabel>Filter by Category</InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Array.from(new Set(exams.map((exam) => exam.category))).map(
                  (category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.examId} // Ensure examId is unique for each row
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={filteredExams}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default Evaluation;
