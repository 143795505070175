import React, { useState, useEffect } from "react";
import {
  Avatar,
  Container,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  instituteUserUpdateProfile,
  instituteUserUpdatePassword,
  instituteReloadUser,
} from "../redux/actions/userAction";
import { INSTITUTE_UPDATE_PROFILE_RESET } from "../redux/constants/userConstants";
import { INSTITUTE_UPDATE_PASSWORD_RESET } from "../redux/constants/userConstants";
import { message } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import Password from "@mui/icons-material/Password";
import moment from "moment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PageTitle from "../Components/PageTitle/PageTitle";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Footer from "../Components/Footer";
import StudentDashboardNavbar from "./StudentDashboardNavbar";
import axios from "../axiosInstance";
import { useLocation } from "react-router-dom";
import imageCompression from 'browser-image-compression';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { error, isUpdated, loading } = useSelector((state) => state.profile);
  const [value, setValue] = useState(0);
  const [studentName, setStudentName] = useState(user?.studentName);
  const [studentEmail, setStudentEmail] = useState(user?.studentEmail);
  const [studentPhone, setStudentPhone] = useState(user?.studentPhone);
  const [parentName, setParentName] = useState(user?.parentName);
  const [parentEmail, setParentEmail] = useState(user?.parentEmail);
  const [parentPhone, setParentPhone] = useState(user?.parentPhone);
  const created = moment(user?.createdAt).format("lll");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [studentEnrollCourses, setStudentEnrollCourses] = useState([]);
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState("");

  const location = useLocation();
  const { totalInstitute, totalCourses, RequestedCourses } =
    location.state || {};
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
 const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      message.success("Profile Updated Successfully");
      dispatch({
        type: INSTITUTE_UPDATE_PASSWORD_RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error, isUpdated]);

  
  // Profile Image upload and Compress the image 
const handleImageChange = async (event) => {
  const file = event.target.files[0];
  const maxSizeInMB = 25;

  if (file) {
    if (file.size / (1024 * 1024) > maxSizeInMB) {
      message.error(`Image size should not exceed ${maxSizeInMB} MB.`);
      return;
    }
    // Compress the image before conversion to base64
    const options = {
      maxSizeMB: 1, 
      maxWidthOrHeight: 1024, 
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result;
        const userData = {
          studentName,
          studentEmail,
          studentPhone,
          parentName,
          parentEmail,
          parentPhone,
          profileImage: base64Image,
        };
        await dispatch(instituteUserUpdateProfile(userData));
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      studentName,
      studentEmail,
      studentPhone,
      parentName,
      parentEmail,
      parentPhone,
      profileImage: imageBase64,
    };
    dispatch(instituteUserUpdateProfile(userData));
  };

  useEffect(() => {
    if (user) {
      setStudentName(user.studentName);
      setStudentEmail(user.studentEmail);
      setStudentPhone(user.studentPhone);
      setParentName(user.parentName);
      setParentEmail(user.parentEmail);
      setParentPhone(user.parentPhone);
    }
    if (error) {
      message.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      message.success("Profile Updated Successfully");
      dispatch(instituteReloadUser());
      dispatch({ type: INSTITUTE_UPDATE_PROFILE_RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Profile Update Password
  const handleSubmitPassword = async (e, value) => {
    e.preventDefault();
    const formData = {
      oldPassword,
      newPassword,
      confirmPassword,
    };
    dispatch(instituteUserUpdatePassword(formData));
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div>
      <Box sx={{ minHeight: "100vh" }}>
        <StudentDashboardNavbar />
        <PageTitle 
        title={`${user?.studentName}'s - Manage Your Account`} 
        description = "View and update your user profile, track exam history, manage courses, and access performance reports. Keep your academic progress organized and up-to-date."
        keywords="user profile, account settings, exam history, performance report, course management, test records, online learning profile, student dashboard"
        />
        <Container
          sx={{
            mt: { xs: 12, sm: 12, md: 12, lg: 14 },
            minHeight: "100vh",
            mb: 10,
          }}
        >
          <Container
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Profile"
                {...TabPanel(0)}
                sx={{
                  fontWeight: 700,
                }}
              />
              <Tab
                label="Update"
                {...TabPanel(1)}
                sx={{
                  fontWeight: 700,
                }}
              />
              <Tab
                label="Password"
                {...TabPanel(2)}
                sx={{
                  fontWeight: 700,
                }}
              />
            </Tabs>
          </Container>

          <CustomTabPanel value={value} index={0}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              sx={{
                mt: 3,
                mb: 3,
                fontFamily: "Poppins, sans-serif",
                color: "#2C3E50",
                letterSpacing: "2.5px",
                lineHeight: 1.8,
              }}
            >
              Profile Information
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  borderRadius: 4,
                  maxWidth: 600,
                  width: "100%",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                {/* Background Image */}
                <Box
                  sx={{
                    width: "100%",
                    height: 200,
                    backgroundImage: `url('https://png.pngtree.com/thumb_back/fh260/back_our/20190619/ourmid/pngtree-company-profile-corporate-culture-exhibition-board-display-poster-material-image_131622.jpg')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "12px 12px 0 0",
                    position: "relative",
                  }}
                >
                  {/* Avatar - Positioned in the center */}
                  {loading ? (
                    <CircularProgress
                      sx={{
                        position: "absolute",
                        bottom: -60,
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    />
                  ) : (
                    <>
                      <Avatar
                        src={image || user?.profileImage}
                        alt={user?.studentName}
                        sx={{
                          width: 120,
                          height: 120,
                          position: "absolute",
                          bottom: -60,
                          left: "50%",
                          transform: "translateX(-50%)",
                          border: "5px solid white",
                        }}
                      />

                      {/* Add Icon to change the image */}
                      <IconButton
                        sx={{
                          position: "absolute",
                          bottom: -60,
                          left: "calc(50% + 40px)",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          boxShadow: 2,
                        }}
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        // onClick={handleClick}
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={handleImageChange}  
                        />
                        <AddCircleIcon sx={{ fontSize: 30 }} />
                      </IconButton>
                    </>
                  )}
                </Box>

                {/* User Info */}
                <Box sx={{ marginTop: 8 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: 2,
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                      marginBottom: 1,
                    }}
                  >
                    {user.studentName}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      mt: 2,
                      fontSize: "18px",
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                      wordWrap: "break-word",
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {user.studentEmail}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      mt: 2,
                      fontSize: "18px",
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                      wordWrap: "break-word",
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {user.studentPhone}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      mt: 2,
                      fontSize: "18px",
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                      wordWrap: "break-word",
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {/* Joined On : */}
                    {created}
                  </Typography>
                </Box>

                {/* Stats Section: Total Institutes, Courses, Required Courses */}
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ marginTop: 2, pb: 5 }}
                >
                  <Grid item>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Total Institutes
                      </Typography>
                      <Typography
                        variant="h5"
                        color="text.secondary"
                        sx={{ fontWeight: "bold", mt: 2 }}
                      >
                        {totalInstitute}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Total Courses
                      </Typography>
                      <Typography
                        variant="h5"
                        color="text.secondary"
                        sx={{ fontWeight: "bold", mt: 2 }}
                      >
                        {totalCourses}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Required Courses
                      </Typography>
                      <Typography
                        variant="h5"
                        color="text.secondary"
                        sx={{ fontWeight: "bold", mt: 2 }}
                      >
                        {RequestedCourses}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              sx={{
                mt: 3,
                mb: 3,
                fontFamily: "Poppins, sans-serif",
                color: "#2C3E50",
                letterSpacing: "2.5px",
                lineHeight: 1.8,
              }}
            >
              Update Profile
            </Typography>
            <Paper
              elevation={3}
              sx={{
                p: { md: 2, xs: 0 },
                borderRadius: 4,
                width: "100%",
              }}
            >
              <Container
                onSubmit={handleSubmit}
                component="form"
                className="form_style border-style"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
                    <EditIcon />
                  </Avatar>
                  <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="name"
                    label="Full Name"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Full Name"
                    value={studentName}
                    onChange={(e) => setStudentName(e.target.value)}
                  />

                  <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="email"
                    label="E-mail"
                    name="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="E-mail"
                    value={studentEmail}
                    onChange={(e) => setStudentEmail(e.target.value)}
                  />
                  <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="phone"
                    label="Phone"
                    name="phone"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Phone Number"
                    value={studentPhone}
                    onChange={(e) => setStudentPhone(e.target.value)}
                  />
                  <Typography
                    color="text.secondary"
                    sx={{
                      mb: 3,
                      fontSize: "18px",
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Parents Information
                  </Typography>
                  <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="parentName"
                    label="Parent Full Name"
                    name="parentName"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Parent Full Name"
                    value={parentName}
                    onChange={(e) => setParentName(e.target.value)}
                  />

                  <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="parentEmail"
                    label="Parent Email"
                    name="parentEmail"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Parent E-mail"
                    value={parentEmail}
                    onChange={(e) => setParentEmail(e.target.value)}
                  />

                  <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="ParentPhone"
                    label="Parent Phone Number"
                    name="ParentPhone"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Parent Phone Number"
                    value={parentPhone}
                    onChange={(e) => setParentPhone(e.target.value)}
                  />
                </Box>
                <Button
                  disabled={loading}
                  variant="contained"
                  type="submit"
                  color="primary"
                  className="courses_desc"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    textTransform: "none",
                    fontFamily: "Poppins, sans-serif",
                    letterSpacing: ".1rem",
                  }}
                >
                  {loading ? "loading..." : "Update Profile"}
                </Button>
              </Container>
            </Paper>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              sx={{
                mt: 3,
                mb: 3,
                fontFamily: "Poppins, sans-serif",
                color: "#2C3E50",
                letterSpacing: "2.5px",
                lineHeight: 1.8,
              }}
            >
              Change Password
            </Typography>
            <Paper
              elevation={3}
              sx={{
                p: { md: 2, xs: 0 },
                borderRadius: 4,
                width: "100%",
              }}
            >
              <Container
                onSubmit={handleSubmitPassword}
                component="form"
                className="form_style border-style"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
                    <Password />
                  </Avatar>

                  {/* <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="oldPassword"
                    name="password"
                    label="Old Password"
                    type="password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  /> */}

                  <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="oldPassword"
                    name="password"
                    label="Old Password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="NewPassword"
                    name="password"
                    label="New Password"
                    type="password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  /> */}

                  <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="NewPassword"
                    name="password"
                    label="New Password"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowNewPassword}
                            edge="end"
                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* <TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="ConfirmPassword"
                    name="password"
                    label="Confirm Password"
                    type="password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  /> */}

<TextField
                    sx={{
                      mb: 3,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                    fullWidth
                    id="ConfirmPassword"
                    name="password"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Button
                  variant="contained"
                  type="submit"
                  className="courses_desc"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    textTransform: "none",
                    fontFamily: "Poppins, sans-serif",
                    letterSpacing: ".1rem",
                  }}
                >
                  Update Password
                </Button>
              </Container>
            </Paper>
          </CustomTabPanel>
        </Container>
        <Footer />
      </Box>
    </div>
  );
}
