/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import axios from "../axiosInstance";
import PageTitle from "../Components/PageTitle/PageTitle";
import { Box } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Modal } from "antd";
import AccessPublicInstructions from "./AccessPublicInstructions";
import AccessPublicNavbar from "./AccessPublicNavbar";

const WriteAccessPublicExam = () => {
  // const { user } = useSelector((state) => state.user);
  const { examId } = useParams();
  const [examData, setExamData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [result, setResult] = useState({});
  const navigate = useNavigate();
  const [view, setView] = useState("accessPublicInstructions");
  const [timeLeft, setTimeLeft] = useState({ minutes: 0, seconds: 0 });
  const [timeUp, setTimeUp] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [examStarted, setExamStarted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const accessPublicUserData = JSON.parse(
    localStorage.getItem("accessPublicUserData")
  );

  // Handle the modal visibility
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    clearInterval(intervalId);
    setTimeUp(true);
    localStorage.removeItem(`timer-${examId}`);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Fetch exam data
  const getExamData = async () => {
    try {
      const response = await axios.get(
        `/api/v1/exams/get-access-public-exam-by-id/${examId}`
      );
      setQuestions(response?.data?.data?.questions);
      setExamData(response?.data?.data);

      const savedTime = JSON.parse(localStorage.getItem(`timer-${examId}`));
      if (savedTime) {
        setTimeLeft(savedTime);
      } else {
        setTimeLeft({
          minutes: Math.floor(response?.data?.data?.duration),
          seconds: 0,
        });
      }
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };
  // Function to handle checkbox changes based on user selection
  const handleCheckboxChange = (optionIndex) => {
    const updatedOptions = { ...selectedOptions };

    // Determine if the question should allow multiple selections
    const isSingleSelect =
      questions[selectedQuestionIndex]?.correctOption.length === 1;

    if (isSingleSelect) {
      // If it's a single-select question, only one option can be selected
      updatedOptions[selectedQuestionIndex] = {
        [optionIndex]: !selectedOptions[selectedQuestionIndex]?.[optionIndex],
      };

      // Ensure only the selected option is checked, others are false
      Object.keys(updatedOptions[selectedQuestionIndex]).forEach((key) => {
        if (key !== optionIndex.toString()) {
          updatedOptions[selectedQuestionIndex][key] = false;
        }
      });
    } else {
      // If it's a multi-select question, toggle the selected option
      updatedOptions[selectedQuestionIndex] = {
        ...selectedOptions[selectedQuestionIndex],
        [optionIndex]: !selectedOptions[selectedQuestionIndex]?.[optionIndex],
      };
    }

    setSelectedOptions(updatedOptions);
  };

  // Calculate Exam Result
  const calculateResult = async () => {
    try {
      let correctAnswers = [];
      let wrongAnswers = [];
      const marksPerQuestion = examData.totalMarks / examData.questions.length;
      const negativeMarking = examData?.negativeMarking || 0;

      questions.forEach((question, index) => {
        const selected = selectedOptions[index];
        if (selected) {
          const selectedOptionsIndexes = Object.keys(selected).filter(
            (key) => selected[key]
          );
          // Compare selected options against correct options
          const correctOptions = question.correctOption.map(String);
          const isCorrect =
            selectedOptionsIndexes.sort().toString() ===
            correctOptions.sort().toString();
          if (isCorrect) {
            correctAnswers.push(question);
          } else {
            wrongAnswers.push(question);
          }
        }
      });

      const negativeMarks = wrongAnswers.length * negativeMarking;
      const totalMarks = questions.length * marksPerQuestion;
      const obtainedMarks =
        correctAnswers.length * marksPerQuestion - negativeMarks;

      let verdict = "PASS";
      if (obtainedMarks < examData.passingMarks) {
        verdict = "FAIL";
      }

      const tempResult = {
        correctAnswers,
        wrongAnswers,
        verdict,
        totalMarks,
        obtainedMarks,
      };
      setResult(tempResult);
      // Add Access Public Exam Reports Api call
      const response = await axios.put(
        `/api/v1/exams/access-public-user-add-report`,
        {
          id: accessPublicUserData._id,
          report: [
            {
              examId: examId,
              examName: examData?.name,
              examSet: examData?.examSet,
              result: tempResult,
              totalMarks: totalMarks,
              passingMarks: examData?.passingMarks,
              category: examData?.category,
              instituteId: examData?.instituteId,
              instituteCourseId: examData?.instituteCourseId,
              vendorId: examData?.vendorId,
              duration: examData?.duration,
              startDate: examData?.startDate,
              endDate: examData?.endDate,
            },
          ],
        }
      );
      if (response.data.success) {
        setView("result");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  // Start Timer Function
  const startTimer = () => {
    let totalSeconds = timeLeft.minutes * 60 + timeLeft.seconds;
    const intervalId = setInterval(() => {
      if (totalSeconds > 0) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        setTimeLeft({ minutes, seconds });
        localStorage.setItem(
          `timer-${examId}`,
          JSON.stringify({ minutes, seconds })
        );
        totalSeconds--;
      } else {
        clearInterval(intervalId);
        setTimeUp(true);
      }
    }, 1000); // 1 second => 1000ms
    setIntervalId(intervalId);
  };

  useEffect(() => {
    if (timeUp && view === "questions") {
      clearInterval(intervalId);
      calculateResult();
      localStorage.removeItem(`timer-${examId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeUp]);

  useEffect(() => {
    if (examId) {
      getExamData(examId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Store data in local storage
  useEffect(() => {
    return () => {
      localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
      localStorage.setItem("selectedQuestionIndex", selectedQuestionIndex);
      localStorage.setItem("timeLeft", JSON.stringify(timeLeft));
    };
  }, [selectedOptions, selectedQuestionIndex, timeLeft]);

  // Restore data from local storage
  useEffect(() => {
    const storedSelectedOptions = JSON.parse(
      localStorage.getItem("selectedOptions")
    );
    const storedSelectedQuestionIndex = localStorage.getItem(
      "selectedQuestionIndex"
    );
    const storedTimeLeft = JSON.parse(localStorage.getItem("timeLeft"));
    if (
      storedSelectedOptions &&
      storedSelectedQuestionIndex !== null &&
      storedTimeLeft
    ) {
      setSelectedOptions(storedSelectedOptions);
      setSelectedQuestionIndex(parseInt(storedSelectedQuestionIndex));
      setTimeLeft(storedTimeLeft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      // Clear local storage when the exam is submitted
      localStorage.removeItem("selectedOptions");
      localStorage.removeItem("selectedQuestionIndex");
      localStorage.removeItem("timeLeft");
      localStorage.removeItem(`timer-${examId}`);
      exitFullScreen();
      setExamStarted(false);
    };
  }, [examId]);

  // Full screen utility
  const requestFullScreen = (element) => {
    if (element?.requestFullscreen) {
      element?.requestFullscreen();
    } else if (element?.mozRequestFullScreen) {
      element?.mozRequestFullScreen();
    } else if (element?.webkitRequestFullscreen) {
      element?.webkitRequestFullscreen();
    } else if (element?.msRequestFullscreen) {
      element?.msRequestFullscreen();
    }
    setExamStarted(false);
  };

  // Handle full screen function
  const handleStartExam = () => {
    setView("questions");
    requestFullScreen(document?.documentElement);
    startTimer();
    setExamStarted(true);
  };

  // Exit Full screen function
  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };
  // Handle Question Click Function
  const handleQuestionClick = (index) => {
    setSelectedQuestionIndex(index);
    setView("questions");
  };
  // Get SelectedOptions in questions and show the all questions list
  const storedSelectedOptions = JSON.parse(
    localStorage.getItem("selectedOptions")
  );

  return (
    <>
      {/* <Navbar /> */}
      {!examStarted && <AccessPublicNavbar />}
    <PageTitle
      title="Take Online Exams – MCQ & Subjective Tests"
      description="Appear for online MCQ and subjective exams with ease. Access timed tests, submit answers, and get instant results. Join now to take your exam!"
      keywords="take online exam, appear for test, MCQ exam, subjective test, online exam submission, timed test, exam portal"  
      />
      {examData && (
        <Container
          className="mt-2"
          sx={{ mt: { xs: 12, sm: 12, md: 12, lg: 12 } }}
        >
          <div className="divider" style={{ marginTop: "10%" }}></div>
          <h2 className="text-center">{examData?.name}</h2>
          <div className="divider"></div>
          {view === "accessPublicInstructions" && (
            <AccessPublicInstructions
              examData={examData}
              setView={handleStartExam}
              question={questions.length}
            />
          )}
          {view === "questions" && (
            <div className="flex flex-col gap-2" style={{ marginTop: "3%" }}>
              <div className="exam-info-container">
                {examData?.negativeMarking !== null && (
                  <span className="info-item">
                    Negative Marks: -
                    <span className="info-badge negative">
                      {examData.negativeMarking}
                    </span>
                  </span>
                )}
                <span className="info-item">
                  Answered:
                  <span className="info-badge answered">
                    {selectedQuestionIndex}
                  </span>
                </span>
                <span className="info-item">
                  Time:
                  <span className="time-display">
                    {timeLeft.minutes.toString().padStart(2, "0")}:
                    {timeLeft.seconds.toString().padStart(2, "0")}
                  </span>
                </span>
              </div>
              {/* <Box className="flex justify-between" sx={{ mt: 5 }}>
                <p
                  style={{ whiteSpace: "pre-line" }}
                  className="flex justify-between"
                >
                  {selectedQuestionIndex + 1} : {""}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: questions[selectedQuestionIndex]?.name,
                    }}
                  ></span>
                </p>
              </Box> */}
              <Box className="flex flex-col" sx={{ mt: 5 }}>
                {/* Index number and text on the same line */}
                <div
                  className="flex items-center"
                  style={{ alignItems: "flex-start" }}
                >
                  <span style={{ marginRight: "5px" }}>
                    {selectedQuestionIndex + 1}:
                  </span>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {questions[selectedQuestionIndex]?.name
                      ?.filter((item) => item.type === "text")
                      .map((item, index) => (
                        <span
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        />
                      ))}
                  </span>
                </div>
                {/* Render images below the text */}
                <div style={{ marginTop: "10px" }}>
                  {questions[selectedQuestionIndex]?.name
                    ?.filter((item) => item.type === "image")
                    .map((item, index) => (
                      <div key={index}>
                        <img
                          src={item.content}
                          alt={`Option ${index}`}
                          style={{ maxWidth: "100px" }}
                        />
                      </div>
                    ))}
                </div>
              </Box>

              {/* <div className="flex flex-col gap-2">
                <>
                  {questions[selectedQuestionIndex]?.options.map(
                    (option, index) => {
                      return (
                        <div
                          className="form-check"
                          key={index}
                          style={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`option-${index}`}
                            checked={
                              !!selectedOptions[selectedQuestionIndex]?.[index]
                            }
                            onChange={() => handleCheckboxChange(index)}
                            style={{
                              cursor: "pointer",
                              width: "15px",
                              height: "22px",
                            }}
                          />
                          <label
                            htmlFor={`option-${index}`}
                            style={{
                              cursor: "pointer",
                              whiteSpace: "pre-line",
                              margin: 0,
                              lineHeight: "21px",
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: option,
                              }}
                            ></span>
                          </label>
                        </div>
                      );
                    }
                  )}
                </>
              </div> */}

              <div className="flex flex-col gap-4">
                {questions[selectedQuestionIndex]?.options.map(
                  (option, index) => (
                    <div
                      className="form-check flex items-start gap-2"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`option-${index}`}
                        checked={
                          !!selectedOptions[selectedQuestionIndex]?.[index]
                        }
                        onChange={() => handleCheckboxChange(index)}
                        style={{
                          cursor: "pointer",
                          marginTop: "3px",
                          transform: "scale(1.2)",
                        }}
                      />
                      <label
                        htmlFor={`option-${index}`}
                        className="flex flex-col"
                        style={{
                          cursor: "pointer",
                          whiteSpace: "pre-line",
                          fontSize: "16px",
                        }}
                      >
                        {option.text && (
                          <span
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: option.text,
                            }}
                          />
                        )}
                        {option.images && option.images.length > 0 && (
                          <div style={{ marginTop: "10px" }}>
                            {option.images.map((image, imgIndex) => (
                              <img
                                key={imgIndex}
                                src={image}
                                alt={`Option ${index} image ${imgIndex}`}
                                style={{
                                  maxWidth: "100px",
                                  marginTop: "5px",
                                }}
                              />
                            ))}
                          </div>
                        )}
                      </label>
                    </div>
                  )
                )}
              </div>

              <div
                className="flex gap-2"
                style={{ marginBottom: "30px", marginTop: "20px" }}
              >
                {selectedQuestionIndex > 0 && (
                  <Button
                    variant="outlined"
                    className="primary-outlined-btn"
                    onClick={() => {
                      setSelectedQuestionIndex(selectedQuestionIndex - 1);
                    }}
                  >
                    Previous
                  </Button>
                )}

                {selectedQuestionIndex < questions.length - 1 && (
                  <Button
                    variant="contained"
                    className="primary-contained-btn"
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      setSelectedQuestionIndex(selectedQuestionIndex + 1);
                    }}
                  >
                    Next
                  </Button>
                )}
                {selectedQuestionIndex === questions?.length - 1 && (
                  <>
                    <Button
                      variant="contained"
                      className="primary-contained-btn"
                      onClick={showModal}
                    >
                      Submit
                    </Button>

                    <Modal
                      title="Confirm Submission"
                      visible={isModalVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <p>Are you sure you want to submit this exam?</p>
                    </Modal>
                  </>
                )}
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  float: "right",
                }}
              >
                {selectedQuestionIndex < questions?.length - 1 && (
                  <>
                    <Button
                      variant="contained"
                      className="primary-contained-btn"
                      onClick={showModal}
                    >
                      Submit
                    </Button>

                    <Modal
                      title="Confirm Submission"
                      visible={isModalVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <p>Are you sure you want to submit this exam?</p>
                    </Modal>
                  </>
                )}
              </Box>
              {/* All Questions length  */}
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "5%",
                    marginTop: "5%",
                    width: "100%",
                    height: "auto",
                    flexWrap: "wrap",
                    backgroundColor: " #f9f9f9",
                  }}
                >
                  {/* All Questions List */}
                  {questions.map((question, index) => (
                    <Box
                      key={index}
                      onClick={() => handleQuestionClick(index)}
                      selected={selectedQuestionIndex === index}
                      style={{
                        backgroundColor:
                          selectedQuestionIndex === index
                            ? "#ffc93c"
                            : storedSelectedOptions &&
                              storedSelectedOptions[index]
                            ? "#42b883"
                            : "#455d7a",
                        fontSize: "17px",
                        color: "#FFF",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        margin: "5px",
                        transition: "background-color 0.3s ease",
                      }}
                    >
                      <ListItemText
                        primary={`${index + 1}`}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      />
                    </Box>
                  ))}
                </Container>
              </Box>
            </div>
          )}
          {view === "result" && (
            <Box>
              <Typography variant="h6" mt={3}>
                <b> Overall Performance Summary</b>
              </Typography>
              <Box display="flex" justifyContent="center" mt={2}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          style={{ background: "#E5E4E2", color: "#000" }}
                        >
                          <h1 style={{ fontWeight: "bold" }}>
                            RESULT : {result.verdict}
                          </h1>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Total Marks</TableCell>
                        <TableCell>{result.totalMarks}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Obtained Marks</TableCell>
                        <TableCell>
                          {result.obtainedMarks?.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Percentage</TableCell>
                        <TableCell>
                          {(
                            (result.obtainedMarks / result.totalMarks) *
                            100
                          ).toFixed(2)}
                          %
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Wrong Answers</TableCell>
                        <TableCell>{result.wrongAnswers.length}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Passing Marks</TableCell>
                        <TableCell>{examData.passingMarks}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Attempted</TableCell>
                        <TableCell>
                          {result.correctAnswers.length +
                            result.wrongAnswers.length}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Accuracy</TableCell>
                        <TableCell>
                          {(
                            (result.correctAnswers.length /
                              (result.correctAnswers.length +
                                result.wrongAnswers.length)) *
                            100
                          ).toFixed(2)}
                          %
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Negative Marks</TableCell>
                        <TableCell>{examData.negativeMarking}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Result</TableCell>
                        <TableCell>{result.verdict}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box className="flex gap-2 mt-2">
                <Button
                  variant="contained"
                  className="primary-contained-btn"
                  onClick={() => {
                    setView("review");
                  }}
                  sx={{ mb: 2 }}
                >
                  Review Answers
                </Button>
              </Box>
            </Box>
          )}

          {/* {view === "review" && (
            <div className="flex flex-col gap-2">
              {questions?.map((question, index) => {
                const selected = selectedOptions[index];
                if (selected) {
                  const selectedOptionsIndexes = Object.keys(selected).filter(
                    (key) => selected[key]
                  );
                  const correctOptions = question.correctOption.map(String);
                  const isCorrect =
                    selectedOptionsIndexes.sort().toString() ===
                    correctOptions.sort().toString();
                  return (
                    <div
                      className={`
              flex flex-col gap-1 p-2 ${isCorrect ? "bg-success" : "bg-error"}
            `}
                      key={index}
                    >
                      <h1
                        className="text-xl"
                        dangerouslySetInnerHTML={{
                          __html: `${index + 1} : ${question.name}`,
                        }}
                      ></h1>
                      <h1 className="text-md">
                        <b>Submitted Answer : {" - "}</b>
                        <ul>
                          {selectedOptionsIndexes?.map((option, i) => (
                            <li
                              key={i}
                              dangerouslySetInnerHTML={{
                                __html: question.options[option],
                              }}
                            ></li>
                          ))}
                        </ul>
                      </h1>
                      <h1 className="text-md">
                        <b>Correct Answer : {" - "} </b>
                        <ul>
                          {question.correctOption?.map((option, i) => (
                            <li
                              key={i}
                              dangerouslySetInnerHTML={{
                                __html: question.options[option],
                              }}
                            ></li>
                          ))}
                        </ul>
                      </h1>
                    </div>
                  );
                }
                return null;
              })}

              <Box className="flex justify-center gap-2" sx={{ mb: 5 }}>
                <Button
                  variant="outlined"
                  className="primary-outlined-btn"
                  onClick={() => {
                    localStorage.removeItem(`timer-${examId}`);
                    localStorage.clear();
                    window.location.reload("/access-public-user");
                  }}
                >
                  Close
                </Button>
              </Box>
            </div>
          )} */}

          {view === "review" && (
            <div className="flex flex-col gap-2">
              {questions?.map((question, index) => {
                const selected = selectedOptions[index];
                if (selected) {
                  const selectedOptionsIndexes = Object.keys(selected).filter(
                    (key) => selected[key]
                  );
                  const correctOptions = question.correctOption.map(String);
                  const isCorrect =
                    selectedOptionsIndexes.sort().toString() ===
                    correctOptions.sort().toString();
                  return (
                    <div
                      className={`
              flex flex-col gap-1 p-2 ${isCorrect ? "bg-success" : "bg-error"}
            `}
                      key={index}
                    >
                      <h1 className="text-xl">
                        <span className="flex flex-col items-start">
                          <span
                            className="flex items-center"
                            style={{ alignItems: "flex-start" }}
                          >
                            <span style={{ marginRight: "5px" }}>
                              {" "}
                              {index + 1} :{" "}
                            </span>{" "}
                            <span style={{ whiteSpace: "pre-line" }}>
                              {question.name?.map((item, i) => {
                                if (item.type === "text") {
                                  return (
                                    <span
                                      key={i}
                                      dangerouslySetInnerHTML={{
                                        __html: item.content,
                                      }}
                                    />
                                  );
                                }
                                return null;
                              })}
                            </span>
                          </span>
                          {question.name?.map((item, i) => {
                            if (item.type === "image") {
                              return (
                                <img
                                  key={i}
                                  src={item.content}
                                  alt={`Option Image ${i}`}
                                  className="question-img mt-0"
                                />
                              );
                            }
                            return null;
                          })}
                        </span>
                      </h1>

                      <h1 className="text-md">
                        <b>Submitted Answer : {" - "} </b>
                        <ul>
                          {selectedOptionsIndexes?.map((option, i) => (
                            <li key={i} style={{ marginTop: "10px" }}>
                              {question.options[option]?.text && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: question.options[option]?.text,
                                  }}
                                />
                              )}
                              {question.options[option]?.images &&
                                Array.isArray(
                                  question.options[option]?.images
                                ) &&
                                question.options[option]?.images.map(
                                  (image, j) => (
                                    <img
                                      key={j}
                                      src={image}
                                      alt={`Submitted Option ${i} Image ${j}`}
                                      className="option-img"
                                    />
                                  )
                                )}
                            </li>
                          ))}
                        </ul>
                      </h1>

                      <h1 className="text-md">
                        <b>Correct Answer : {" - "} </b>
                        <ul>
                          {question.correctOption?.map((option, i) => (
                            <li key={i} style={{ marginTop: "10px" }}>
                              {question.options[option]?.text && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: question.options[option]?.text,
                                  }}
                                />
                              )}
                              {question.options[option]?.images &&
                                Array.isArray(
                                  question.options[option]?.images
                                ) &&
                                question.options[option]?.images.map(
                                  (image, j) => (
                                    <img
                                      key={j}
                                      src={image}
                                      alt={`Correct Option ${i} Image ${j}`}
                                      className="option-img"
                                    />
                                  )
                                )}
                            </li>
                          ))}
                        </ul>
                      </h1>
                    </div>
                  );
                }
                return null;
              })}

              <Box className="flex justify-center gap-2" sx={{ mb: 10 }}>
                <Button
                  variant="outlined"
                  className="primary-outlined-btn"
                  onClick={() => {
                    localStorage.removeItem(`timer-${examId}`);
                    localStorage.clear();
                    window.location.reload("/access-public-user");
                  }}
                >
                  Close
                </Button>
              </Box>
            </div>
          )}
        </Container>
      )}
    </>
  );
};

export default WriteAccessPublicExam;
