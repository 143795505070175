import React from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Typography,
  Box,
  Paper,
  Divider,
  Grid,
  Avatar,
} from "@mui/material";
import Footer from "../Components/Footer";
import {
  Assessment,
  VideoLibrary,
  DocumentScanner,
  Group,
  School,
  Description,
} from "@mui/icons-material"; 

const About = () => {
  return (
    <>
      <Navbar />
      <PageTitle
        title="About Test Series - Revolutionizing Education with GatikAI"
        description="Discover how GatikAI Technologies empowers institutes and students with innovative test series, online exams, video lectures, and more."
        keywords="online exams, test series, video lectures, PDF notes, student management, batch management, AI-powered education, assessment platform, GatikAI, exam reports, live assessments, education technology, e-learning solutions, digital exams"
      />
      <Box
        sx={{
          mt: { xs: 16, sm: 16, md: 16, lg: 18 },
          mb: 8,
          mx: { md: 12, xs: 2 },
          minHeight: "100vh",
          // backgroundColor: "#f9f9f9",
        }}
      >
        {/* <Paper
          elevation={3}
          sx={{ p: 4, borderRadius: "15px", backgroundColor: "#ffffff" }}
        > */}
          <Typography
            variant="h4"
            align="center"
            className="courses_title"
            gutterBottom
            sx={{
              letterSpacing: "3px",
              lineHeight: 1.8,
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#2C3E50",
            }}
          >
          Test Series
          </Typography>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Revolutionizing Education for Institutes and Students
          </Typography>
          <Divider sx={{ mb: 3, mt: 3 }} />

          {/* Introduction Section */}
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 3,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              textAlign: "center",
              letterSpacing: "1.8px",
            }}
          >
            GatikAI Technologies provides a multi-vendor platform that empowers
            institutes to enhance their educational offerings through innovative
            test series. Our services enable institutes to create, manage, and
            deliver online exams, live assessments, and practice tests tailored
            to their students' needs.
          </Typography>

          {/* <Divider sx={{ mb: 3, mt: 5 }} /> */}

          {/* Our Services Section */}
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color="text.secondary"
            sx={{
              mt: 5,
              mb: 5,
              letterSpacing: "2.5px",
              lineHeight: 1.8,
              fontSize: "30px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            Our Comprehensive Services
          </Typography>
          <Grid
            container
            spacing={3}
            sx={{
              mb: 3,
              letterSpacing: "2.5px",
              lineHeight: 1.8,
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            {[
              {
                icon: <Assessment sx={{ fontSize: 60, color: "#007bff" }} />,
                title: "Online Exams",
                description:
                  "Provide students with the opportunity to take online exams in a secure environment.",
              },
              {
                icon: <VideoLibrary sx={{ fontSize: 60, color: "#28a745" }} />,
                title: "Video Lectures",
                description:
                  "Access high-quality video lectures to enhance understanding and retention.",
              },
              {
                icon: (
                  <DocumentScanner sx={{ fontSize: 60, color: "#ffc107" }} />
                ),
                title: "Downloadable Reports",
                description:
                  "Generate and download PDF reports of exam performance and subject analysis.",
              },
              {
                icon: <Group sx={{ fontSize: 60, color: "#17a2b8" }} />,
                title: "Student Management",
                description:
                  "Easily manage student registrations and track their progress.",
              },
              {
                icon: <School sx={{ fontSize: 60, color: "#6f42c1" }} />,
                title: "Batch Management",
                description:
                  "Organize students into batches for effective learning and performance tracking.",
              },
              {
                icon: <Description sx={{ fontSize: 60, color: "#dc3545" }} />,
                title: "PDF Notes",
                description:
                  "Provide comprehensive PDF notes to students for better exam preparation.",
              }, // New Notes Card
            ].map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Paper
                  elevation={8}
                  sx={{
                    textAlign: "center",
                    borderRadius: "15px",
                    transition: "0.5s !important",
                    height: "100% !important",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Avatar sx={{ bgcolor: "transparent", mb: 2 }}>
                    {item.icon}
                  </Avatar>
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    color="text.secondary"
                    sx={{
                      fontWeight: "bold",
                      mb: 1,
                      textAlign: "center",
                      letterSpacing: "2.5px",
                      lineHeight: 1.8,
                      fontFamily: "Poppins, sans-serif",
                      color: "#333",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    color="text.secondary"
                    sx={{
                      mt: 3,
                      fontSize: "16px",
                      fontFamily: "Poppins, sans-serif",
                      lineHeight: 1.8,
                      textAlign: "center",
                      letterSpacing: "1.8px",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ mb: 3, mt: 5 }} />
          {/* Additional Features Section */}
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color="text.secondary"
            sx={{
              mt: 5,
              mb: 5,
              letterSpacing: "2.5px",
              lineHeight: 1.8,
              fontSize: "30px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            Additional Features
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 3,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              letterSpacing: "1.8px",
            }}
          >
            Our platform offers a range of features including live exams,
            practice exams, subjective assessments, and the ability to download
            question papers and answer keys. We provide detailed performance
            analysis, allowing institutes to generate top reports based on exam
            performance.
          </Typography>

          <Divider sx={{ mb: 3, mt: 5 }} />

          {/* Call to Action Section */}
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color="text.secondary"
            sx={{
              mt: 5,
              mb: 5,
              letterSpacing: "2.5px",
              lineHeight: 1.8,
              fontSize: "30px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            Join Us Today
          </Typography>
          <Typography
            variant="h6"
            paragraph
            gutterBottom
            component="div"
            color="text.secondary"
            sx={{
              mt: 3,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              letterSpacing: "1.8px",
            }}
          >
            Partner with GatikAI Technologies to elevate your institute's
            educational offerings. Experience the convenience of our platform
            and unlock the potential of your students with our comprehensive
            test series solutions.
          </Typography>

          <Divider sx={{ mb: 3 }} />

          {/* Contact Us Section in a Card */}
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            color="text.secondary"
            sx={{
              mt: 5,
              mb: 5,
              letterSpacing: "2.5px",
              lineHeight: 1.8,
              fontSize: "30px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="h6"
            paragraph
            gutterBottom
            component="div"
            color="text.secondary"
            sx={{
              mt: 3,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              letterSpacing: "1.8px",
            }}
          >
          Interested in joining our test series or have any questions? Contact us via email or phone!
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              mt: 2,
              mb: 5,
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.8,
              letterSpacing: "1.8px",
            }}
          >
            E-mail: testseries@gatiktech.com
            <br />
            Contact Number: +91-7891555777, +91-8442025283 
          </Typography>
          {/* <Divider sx={{ mb: 3 }} />
        </Paper> */}
      </Box>
      <Footer />
    </>
  );
};

export default About;
