import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";
import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import axios from "../../../axiosInstance";

const PublicExamReports = () => {
  const { user } = useSelector((state) => state.user);
  const [exams, setExams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Get All Exam Data
  const getAllExams = async () => {
    try {
      const response = await axios.get(
        `/api/v1/exams/admin-get-all-public-reports-exams`
      );
      if (response?.data?.success === true) {
        setExams(response.data.data);
        console.log(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllExams();
  }, []);

  // Delete Exam
  const { confirm } = Modal;
  const deleteExam = async (e, examId) => {
    confirm({
      title: "Are you sure you want to delete this Public Exam Report?",
      content:
        "This action will permanently delete the public exam report. Are you sure you want to continue?",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/exams/admin-delete-public-reports-exams",
            {
              data: { examId },
            }
          );
          if (data.success === true) {
            message.success(data.message);
            getAllExams();
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete exam."
          );
        }
      },
      onCancel() {
        console.log("Delete action canceled.");
      },
    });
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 10,
      sortable: false,
    },
    {
      field: "Actions",
      width: 90,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteExam(e, value?.row?.examId)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 130,
      editable: true,
    },
    {
      field: "examName",
      headerName: "Exam Name",
      width: 130,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 110,
      editable: true,
    },
    {
      field: "examSet",
      headerName: "Exam Type",
      width: 110,
      editable: true,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 100,
      editable: false,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 120,
      editable: false,
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 120,
      editable: false,
    },
    {
      field: "obtainedMarks",
      headerName: "Obtained Marks",
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        // Check if the value is a number before formatting
        return params.value ? Number(params.value).toFixed(2) : "0.00";
      },
    },
    {
      field: "verdict",
      headerName: "Verdict",
      width: 120,
      editable: false,
    },
    {
      field: "endDate",
      headerName: "Result Date",
      width: 120,
      editable: false,
    },
  ];

  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(2, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 120,
      editable: true,
    });
  }

  const filteredRows = exams
    .flatMap((exam) =>
      exam.report.map((reportItem) => ({
        ...reportItem,
        email: exam.email || "No Email Provided",
        obtainedMarks: reportItem.result.obtainedMarks || 0,
        verdict: reportItem.result.verdict || "N/A",
      }))
    )
    .filter(
      (item) =>
        item.examName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      rowNumber: index + 1,
    }));

  // Function to export data to PDF with index column
  const exportPDF = (filteredRows) => {
    Modal.confirm({
      title: "Download Report",
      content: "Do you want to download the Student Report as a PDF?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        const doc = new jsPDF();
        const columns = [
          "#",
          "Exam Name",
          "User Email",
          "Total Marks",
          "Passing Marks",
          "Obtained Marks",
          "Verdict",
          "Report Date",
        ];

        const rows = filteredRows.map((report, index) => [
          index + 1,
          report.examName,
          report.email,
          report.totalMarks,
          report.passingMarks,
          report.result.obtainedMarks
            ? report.result.obtainedMarks.toFixed(2)
            : "0.00",
          report.result.verdict,
          moment(report.createdAt).format("MM/DD/YYYY"),
        ]);

        // Add title
        doc.text("Student All Report", 16, 16);

        // Add the table
        doc.autoTable({
          head: [columns],
          body: rows,
          startY: 20,
        });

        // Save the PDF
        doc.save("Student_Report.pdf");
      },
      onCancel() {
        console.log("Download canceled.");
      },
    });
  };

  return (
    <div>
      <PageTitle
        title="Public MCQ Exam Reports - Institute Dashboard & Performance Insights"
        description="Access detailed public exam reports, including MCQ test performance. Track scores, compare rankings, and download exam insights for analysis."
        keywords="test series, public exam reports, MCQ test results, subjective exam performance, online test analytics, student exam scores, competitive exam reports, test series reports"
      />
      <Box>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          All Public Exam Reports
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            Public Exam Reports
          </Typography>
        </Breadcrumbs>
        {/* Search Input */}
        <Box sx={{ pb: 2, mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Exam Name or Email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => exportPDF(filteredRows)}
          startIcon={<DownloadIcon />}
          className="courses_desc"
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Export to PDF
        </Button>
        <Box
          style={{
            width: "auto",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        >
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                rows={filteredRows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default PublicExamReports;
