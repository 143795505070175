import React, { useEffect, useState } from "react";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Box,
  Paper,
  TextField,
  Typography,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { message, Modal } from "antd";
import moment from "moment";
import axios from "../axiosInstance";

const ContactSupportList = () => {
  const [contact, setContact] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);

  // Get All Exam Data
  const getAllContact = async () => {
    try {
      const response = await axios.get(
        `/api/v1/query/admin-get-query-contactSupport`
      );
      if (response.data.success === true) {
        setContact(response.data.vendorMsg);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllContact();
  }, []);

  // Handle Search
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    const filteredData = contact.filter(
      (item) =>
        item.vendorName.toLowerCase().includes(value) ||
        item.vendorEmail.toLowerCase().includes(value) ||
        item.vendorPhone.toLowerCase().includes(value)
    );
    setFilteredContacts(filteredData);
  };

  // Set filteredContacts initially to all contacts
  useEffect(() => {
    setFilteredContacts(contact);
  }, [contact]);

  // Delete Contact
  const { confirm } = Modal;
  const deleteContact = async (e, id) => {
    confirm({
      title:
        "Are you sure you want to delete this Contact Support Vendor Message?",
      content:
        "Once deleted, this contact support vendor message cannot be recovered.",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/query/admin-delete-query-contactSupport",
            {
              data: { id },
            }
          );

          if (data?.success === true) {
            message.success(data?.message);
            getAllContact();
          } else {
            message.error(data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete contact"
          );
        }
      },
      onCancel() {
        console.log("Contact deletion canceled");
      },
    });
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 10,
      sortable: false,
    },
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteContact(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "vendorInstituteId",
      headerName: "Institute Id",
      width: 120,
      editable: true,
    },
    {
      field: "vendorName",
      headerName: "Full Name",
      width: 150,
      editable: true,
    },
    {
      field: "vendorEmail",
      headerName: "E-mail",
      width: 180,
      editable: true,
    },
    {
      field: "vendorPhone",
      headerName: "Phone Number",
      width: 180,
      editable: true,
    },
    {
      field: "vendorMsg",
      headerName: "Message",
      width: 420,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Query Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div>
      <PageTitle 
      title="Contact Support List - View All Support Queries" 
      description="Manage and view all support queries from students and institutes. Track, respond, and resolve issues related to exams, courses, and payments."
      keywords="test series, contact support list, support queries, customer support management, student help requests, institute support, technical support tickets, test series assistance"
      />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Contact Support List
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>
          {" "}
          Contact Support List
        </Typography>
      </Breadcrumbs>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search by Name, Email, or Phone Number"
        value={search}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          ),
        }}
        sx={{
          mb: 3,
          bgcolor: "white",
          borderRadius: "20px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
        }}
      />
      <Box
        style={{
          width: "auto",
          overflowY: "hidden",
          overflowX: "scroll",
        }}
      >
        <Box
          sx={{
            pb: 2,
            display: "flex",
            width: "auto",
            justifyContent: "right",
          }}
        ></Box>

        <Paper sx={{ bgcolor: "white" }}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                // Style for checkbox in header
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              // rows={contact}
              rows={filteredContacts.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
              // checkboxSelection
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default ContactSupportList;
