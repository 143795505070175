import React, { useState, useEffect } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import ShareIcon from "@mui/icons-material/Share";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { message, Modal, Button } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../axiosInstance";

const StudentShareReportAll = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [reportsData, setReportsData] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [userAllReports, setUserAllReports] = useState([]);

  // Get all reports by user
  const getReports = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/admin-get-all-reports`
      );
      const reports = response?.data?.data;

      // Group reports by userEmail and get total count per user
      const userReports = reports.reduce((acc, report) => {
        const { userEmail, userName, instituteCourseId, userId, instituteId } =
          report;
        if (!acc[userEmail]) {
          acc[userEmail] = {
            userName,
            userEmail,
            instituteCourseId,
            instituteId,
            userId,
            totalReports: 0,
          };
        }
        acc[userEmail].totalReports += 1;
        return acc;
      }, {});

      const groupedReports = Object.values(userReports);
      setReportsData(groupedReports);
      setFilteredReports(groupedReports);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  // Search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = reportsData.filter((report) => {
      return (
        report.userName.toLowerCase().includes(query) ||
        report.userEmail.toLowerCase().includes(query)
      );
    });
    setFilteredReports(filtered);
  };

  // View Report by userEmail
  const viewReport = (userEmail) => {
    navigate(`/admin/reports/view/${userEmail}`);
  };

  const { confirm } = Modal;
  // Share functionality
  const handleShare = (report) => {
    setSelectedReport(report);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    navigate("/admin/student/share/group/report", {
      state: { report: selectedReport },
    });
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      field: "Actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="view"
            onClick={() => viewReport(params.row.userEmail)}
          >
            <VisibilityIcon sx={{ color: "#1976d2" }} />
          </IconButton>
          <IconButton
            aria-label="share"
            onClick={() => handleShare(params.row)}
          >
            <ShareIcon sx={{ color: "rgb(25, 118, 210)" }} />
          </IconButton>
        </Box>
      ),
    },
    { field: "userName", headerName: "Student Name", width: 180 },
    { field: "totalReports", headerName: "Total Reports", width: 160 },
    { field: "userEmail", headerName: "Student Email", width: 220 },
  ];

  return (
    <div>
      <PageTitle 
      title="Student Report Sharing - Exam Performance & Insights" 
      description="Share your exam performance report with others. Download and analyze your MCQ exam results for better insights and progress tracking."
      keywords="test series, student report sharing, share exam results, MCQ exam report, subjective exam report, performance insights, test series report, online exam analysis"
      />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Student Share All Report
      </Typography>

      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/exams"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Exams
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Reports</Typography>
      </Breadcrumbs>

      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Student name or Student Email"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon sx={{ color: "#1976d2" }} />
              </IconButton>
            ),
          }}
          sx={{
            mb: 3,
            bgcolor: "white",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": { border: "none" },
              "&:hover fieldset": { border: "none" },
              "&.Mui-focused fieldset": { border: "none" },
            },
          }}
        />
      </Box>
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 500, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.userEmail}
            sx={{
              "& .MuiTablePagination-displayedRows": { color: "black" },
              color: "black",
              [`& .${gridClasses.row}`]: { bgcolor: "white" },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": { color: "white" },
            }}
            rows={filteredReports}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
          />
        </Box>
      </Paper>

      <Modal
        title="Share or Preview Report"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          // <Button key="preview" onClick={handleModalPreview}>
          //   Preview Report
          // </Button>,
          <Button key="share" type="primary" onClick={handleModalOk}>
            Share Report
          </Button>,
        ]}
      >
        <p>
          Would you like to share this report, preview it before sharing, or
          cancel?
        </p>
      </Modal>
    </div>
  );
};

export default StudentShareReportAll;
