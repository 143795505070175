import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import axios from "../../axiosInstance";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Modal, message } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddSubjectiveQuestion from "./AddSubjectiveQuestion";
import EditSubjectiveQuestion from "./EditSubjectiveQuestion";
import { getAllCourse } from "../../redux/actions/userAction";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditSubjectiveExam() {
  // Get Data in exam by Id
  let { id } = useParams();
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.courseData);
  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [passingMarks, setPassingMarks] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [questions, setQuestions] = useState([]);
  const [negativeMarking, setNegativeMarking] = useState("");
  // const { user } = useSelector((state) => state.user);
  const [type, setType] = useState("Subjective");
  const [examSet, setExamSet] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [exam, setExam] = useState([]);
  const [studentCourseId, setStudentCourseId] = useState("");
  const [studentCourse, setStudentCourse] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const examStartDate = moment(startDate).format("MM/DD/YYYY");
  const examEndDate = moment(endDate).format("MM/DD/YYYY");

  // Fetch all courses
  useEffect(() => {
    dispatch(getAllCourse());
  }, [dispatch]);

  useEffect(() => {
    if (courses) {
      setStudentCourse(courses);
    }
  }, [courses]);

  useEffect(() => {
    getAllExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, courses]);

  // Handle Institute Course selection change
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse(selectedCourseName);

    // Assign a fixed ID for "General"
    const generalCourseId = "81af3e57846a42aab058dc4d";

    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setStudentCourseId(selectedCourse._id);
    } else if (selectedCourseName === "General") {
      setStudentCourseId(generalCourseId);
    }
  };

  // Get all Subjective Exams
  const getAllExams = async () => {
    try {
      const response = await axios.get(
        `/api/v1/subjective/get-exam-subjective-by-id/${id}`
      );
      const examData = response?.data?.data;
      setExam(examData);
      setSearchResults(examData.questions);
      setName(examData.name);
      setCategory(examData.category);
      setDuration(examData.duration);
      setTotalMarks(examData.totalMarks);
      setPassingMarks(examData.passingMarks);
      setNegativeMarking(examData.negativeMarking);
      setType(examData.type);
      //   setQuestionType(examData.questionType);
      setExamSet(examData.examSet);
      setStartDate(examData.startDate);
      setEndDate(examData.endDate);
      setQuestions(examData.questions);
      setInstituteId(examData.instituteId);
      setVendorId(examData.vendorId);
      // Set course selection
      const selectedCourse = courses.find(
        (course) => course._id === examData.instituteCourseId
      );

      if (selectedCourse) {
        setStudentCourse(selectedCourse.courseName);
        setStudentCourseId(selectedCourse._id);
      } else if (examData.instituteCourseId === "81af3e57846a42aab058dc4d") {
        setStudentCourse("General");
        setStudentCourseId("81af3e57846a42aab058dc4d");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Edit Exam by Id
  const handleSubmit = async (e) => {
    e.preventDefault();
    Modal.confirm({
      title: "Are you sure you want to Edit this Subjective Exam?",
      content: "This action will update the selected subjective exam details.",
      okText: "Yes, Update",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const addExam = {
            name,
            category,
            duration,
            totalMarks,
            passingMarks,
            negativeMarking,
            examSet,
            type,
            startDate: examStartDate,
            endDate: examEndDate,
            id: id,
            instituteId: instituteId,
            vendorId: vendorId,
            instituteCourseId: studentCourseId,
          };

          const { data } = await axios.put(
            "/api/v1/subjective/admin-update-exam-subjective",
            addExam
          );

          if (data.success === true) {
            message.success(data.message);
            // Save the updated exam data to localStorage
            localStorage.setItem("examData", JSON.stringify(data));
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(error?.response?.data?.message || "An error occurred");
        }
      },
      onCancel() {
        console.log("Exam update canceled");
      },
    });
  };

  return (
    <div>
      <PageTitle 
      title="Edit Subjective Exam - Modify Questions & Exam Details" 
      description="Update and manage your subjective exam with ease. Edit questions, adjust exam settings, and enhance the test for a better evaluation experience."
      keywords="test series, edit subjective exam, update exam questions, modify subjective test, manage online exams, subjective test settings, exam customization, test series editing"
      />
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          sx={{
            mb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#2C3E50",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Edit Subjective Exam And Question
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mb: 5,
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          }}
        >
          <MUILink
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Dashboard
          </MUILink>
          <MUILink
            component={Link}
            to="/admin/subjective/exams"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            Subjective Exams
          </MUILink>
          <Typography sx={{ color: "primary.main" }}>
            Edit Exams And Questions
          </Typography>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Exam" {...TabPanel(0)} />
            <Tab label="Question" {...TabPanel(1)} />
            <Tab label="Add Question" {...TabPanel(2)} />
          </Tabs>
        </Box>

        {/* Add Exams */}
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Edit Subjective Exam
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              select
              name="studentCourse"
              label="Institute Course"
              placeholder="Institute Course"
              value={studentCourse || ""}
              onChange={handleChangeStudentCourse}
              fullWidth
              variant="outlined"
            >
              <MenuItem disabled>Select Course</MenuItem>
              <MenuItem key="generalCourse" value="General">
                General
              </MenuItem>
              {courses &&
                courses.map((course) => (
                  <MenuItem key={course._id} value={course.courseName}>
                    {course.courseName}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Exam Name"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Exam Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="category"
              label="Category"
              name="category"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="duration"
              label="Duration (mins)"
              name="duration"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Duration (mins)"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="totalMarks"
              label="Total Marks"
              name="totalMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Total Marks"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="passingMarks"
              label="Passing Marks"
              name="passingMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Passing Marks"
              value={passingMarks}
              onChange={(e) => setPassingMarks(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                "& input[type='number']": {
                  MozAppearance: "textfield",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="negativeMarks"
              label="Negative Marks"
              name="negativeMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Negative Marks"
              value={negativeMarking}
              onChange={(e) => setNegativeMarking(e.target.value)}
            />

            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Exam Set{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={examSet}
                  label="Exam Set"
                  onChange={(e) => setExamSet(e.target.value)}
                >
                  <MenuItem value="Practice Exam">Practice Exam </MenuItem>
                  <MenuItem value="Live Exam">Live Exam</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
            </Box>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Update Subjective Exam
            </Button>
          </Container>
        </CustomTabPanel>

        {/* Add Questions */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Edit Subjective Question
          </Typography>
          <EditSubjectiveQuestion
            vendor_Id={vendorId}
            institute_Id={instituteId}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Typography
            variant="h6"
            sx={{
              pb: 2,
              pt: 3,
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              letterSpacing: "2.5px",
              lineHeight: 1.8,
            }}
          >
            Add Subjective Question
          </Typography>
          <AddSubjectiveQuestion
            vendor_Id={vendorId}
            institute_Id={instituteId}
          />
        </CustomTabPanel>
      </Box>
    </div>
  );
}
