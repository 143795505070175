import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { jsPDF } from "jspdf";
import PageTitle from "../PageTitle/PageTitle";
import axios from "../../axiosInstance";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleBackToHome = () => {
    navigate("/");
  };
  
  // Payment Receipt Download
  const handleDownload = () => {
    if (!paymentDetails) return;

    const {
      transactionID,
      amount,
      createdAt,
      status,
      name,
      number,
      email,
      requestedPlan,
    } = paymentDetails;

    const formattedDate = formatDate(createdAt);
    const formattedTime = formatTime(createdAt);

    const doc = new jsPDF();

    // Add title
    doc.setFontSize(18);
    doc.text("Payment Receipt", 105, 20, null, null, "center");
    doc.setFontSize(14);
    doc.text(
      "Test Series & Institute Management System",
      105,
      30,
      null,
      null,
      "center"
    );

    // Add divider
    doc.setLineWidth(0.5);
    doc.line(20, 35, 190, 35);

    // Add transaction details
    doc.setFontSize(12);
    doc.text("Transaction Details:", 20, 45);
    doc.text(`Transaction ID: ${transactionID}`, 20, 55);
    doc.text(`Amount: ₹${amount}`, 20, 65);
    doc.text(`Date: ${formattedDate}`, 20, 75);
    doc.text(`Time: ${formattedTime}`, 20, 85);
    doc.text(`Payment Status: ${status}`, 20, 95);
    doc.text(`Requested Plan: ${requestedPlan}`, 20, 105);

    // Add user details
    doc.text("User Details:", 20, 115);
    doc.text(`User Name: ${name}`, 20, 125);
    doc.text(`User Phone: ${number}`, 20, 135);
    doc.text(`User Email: ${email}`, 20, 145);

    // Add footer
    doc.setFontSize(10);
    doc.text("Thank you for your payment!", 105, 200, null, null, "center");

    // Save the PDF
    doc.save("Payment_Receipt.pdf");
  };

  useEffect(() => {
    if (!orderId) {
      console.error("Order ID is missing!");
      return;
    }

    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `/api/get-payment-details?orderId=${orderId}`
        );
        setPaymentDetails(response.data.payment);
        setLoading(false);
      } catch (err) {
        message.error(err.message || "Error fetching payment details");
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [orderId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const { transactionID, amount, createdAt, status, name, requestedPlan } =
    paymentDetails || {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(",", "");
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  return (
    <>
      <PageTitle 
      title="Payment Successful - Access Your Test Series Now" 
      description="Your payment has been successfully processed! Start your test series, access MCQ and subjective exams, and enhance your learning experience."
      keywords="test series, test series payment success, payment success, test series purchase, exam access, online test payment, successful transaction, test series dashboard activation, course activation, MCQ and subjective test"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
          padding: 2,
        }}
      >
        <Card
          sx={{ maxWidth: 500, padding: 3, textAlign: "center", boxShadow: 3 }}
        >
          <CheckCircleIcon
            sx={{ fontSize: 80, color: "green", marginBottom: 2 }}
          />
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Payment Successful!
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Thank you for your payment, {name}.
          </Typography>

          <CardContent>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Transaction Details
            </Typography>
            <Grid container spacing={2} textAlign="left">
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Transaction ID:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{transactionID}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Amount:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {"\u20B9"}
                  {amount}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Date:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{formatDate(createdAt)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Time:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{formatTime(createdAt)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Payment Status:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{status}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  Requested Plan:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{requestedPlan}</Typography>
              </Grid>
            </Grid>
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              marginTop: 3,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleBackToHome}
              sx={{ textTransform: "none" }}
            >
              Back to Home
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDownload}
              sx={{ textTransform: "none" }}
            >
              Download Details
            </Button>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default PaymentSuccess;
