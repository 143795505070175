import { useEffect, useState } from "react";
import { message } from "antd";

const useInstituteUser = () => {
  const [studentCourse, setStudentCourse] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("instituteUser");

    if (storedUser) {
      const userObject = JSON.parse(storedUser);
      setStudentCourse(userObject?.studentCourse);
    } else {
      message.error("No user data found.");
    }
  }, []);

  return studentCourse;
};

export default useInstituteUser;
