import React, { useState } from "react";
import DemoNavbar from "./DemoNavbar";
import DemoFooter from "./DemoFooter";
import PageTitle from "../PageTitle/PageTitle";
import { Box, Grid, Typography } from "@mui/material";
import AllInstitutes from "./AllInstitutes";
import testseries1 from "../../assets/BannerDemo.jpg";


const DemoHome = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };
  return (
    <>
      <DemoNavbar />
      <PageTitle
      // title="Welcome to Test Series! Explore our wide range of exam preparations"
      title="Test Series & Institute Management System | Online Exam Preparation"
      description="Prepare for exams with Test Series & Institute Management System. Take online MCQ and subjective tests, manage institutes, and track student performance. Elevate your learning today!"
      keywords="test series, online test series, exam preparation, MCQ test, subjective test, institute management, coaching software, online learning platform, mock tests, digital education, test series demo, student performance tracking, online exam system, competitive exam preparation, educational platform"
    />
      <Box
        sx={{
          backgroundImage: isLoaded
            ? // ? `url(${image})`
              `url(${testseries1})`
              // `url(https://static.vecteezy.com/system/resources/previews/002/130/421/non_2x/online-exam-internet-distance-school-test-banner-landing-page-clock-computer-screen-web-quiz-check-list-boxes-questionnaire-application-digital-education-online-exam-blue-background-vector.jpg)`
            : `url(${"placeholder"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: { xs: "scroll", md: "fixed" },
          height: { xs: "980px", md: "980px" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
        <Grid
          container
          sx={{
            pt: { xs: 45, md: 48 },
            px: { xs: 2, md: 5 },
            color: "#fff",
            opacity: 1,
            position: "relative",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: "900",
              fontSize: { xs: "30px", sm: "30px", md: "70px" },
              // fontFamily: Fontfamily.playfairDisplay,
              letterSpacing: "3.8px",
              backgroundColor: "rgba(fff, 0, 0, 0.8)",
            }}
          >
            Test Series <br />
        & Institute Management System
          </Typography>
          <Typography
            sx={{
              width: "100%",
              fontSize: { xs: "17px", sm: "18px", md: "22px", lg: "25px" },
              lineHeight: "2.0",
              textAlign: "center",
              fontWeight: "bold",
              letterSpacing: { xs: "1.5px", md: "2.5px" },
              pt: 2,
              m: { xs: 0, md: -1 },
            }}
          >
            Don’t just study... <br />
            Test your edge and achieve greatness!
          </Typography>
        </Grid>
        <img
          // src={image}
          src="https://s3.envato.com/files/285337851/online-exam-management-banner.jpg"
          alt="background"
          style={{ display: "none" }}
          onLoad={handleImageLoad}
        />
      </Box>

      {/* All Institutes Cards */}
      <AllInstitutes />
      
      <DemoFooter />
    </>
  );
};

export default DemoHome;


