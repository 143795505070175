import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { message, Modal } from "antd";
import moment from "moment";
import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";
import EditVendor from "./EditVendor";

const EditorVendorList = () => {
  const [vendors, setVendors] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [response, setResponse] = useState(null);
  // get-all-vendor
  const displayVendor = async () => {
    try {
      const { data } = await axios.get(
        "/api/v1/common/admin-get-editor-vendor"
      );
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    displayVendor();
  }, []);

  //delete User
  const { confirm } = Modal;
  const deleteUser = async (e, id, role_name) => {
    confirm({
      title: `Are you sure you want to delete this ${
        role_name || "user"
      } account?`,
      content: `Once deleted, this action cannot be undone.`,
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/common/admin-delete-vendor",
            {
              data: { id },
            }
          );
          if (data?.success === true) {
            message.success(data.message);
            displayVendor();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete user."
          );
        }
      },
      onCancel() {
        console.log("Delete action canceled");
      },
    });
  };

  // Function to toggle user's active status
  const toggleUserActiveStatus = async (id, isActive, role_name) => {
    const action = isActive ? "activate" : "deactivate";
    confirm({
      title: `Are you sure you want to ${action} this ${
        role_name || "user"
      } account?`,
      content: `Once you ${action} this account, it will be ${action}d.`,
      okText: `Yes, ${action}`,
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.post(
            "/api/v1/auth/admin-active-delete-user-vendor",
            {
              id,
              isActive: isActive,
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            displayVendor();
          }
          setResponse(data);
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to change status."
          );
        }
      },
      onCancel() {
        console.log("Status change canceled");
      },
    });
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 10,
      sortable: false,
    },
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) =>
              deleteUser(e, value?.row?._id, value?.row?.role_name)
            }
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditVendor
            vendorId={value?.row?._id}
            refreshData={displayVendor}
            name={value?.row?.name}
            email={value?.row?.email}
            phone={value?.row?.phone}
          />
        </Box>
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 170,
      renderCell: (params) => {
        const isActive = params?.row?.isActive === true;
        return (
          <select
            value={isActive.toString()}
            onChange={(e) =>
              toggleUserActiveStatus(
                params.row._id,
                e.target.value === "true",
                params.row?.role_name
              )
            }
            style={{
              padding: "6px 12px",
              fontSize: "14px",
              borderRadius: "8px",
              border: `1px solid ${isActive ? "green" : "red"}`,
              backgroundColor: isActive ? "#e0ffe0" : "#ffe0e0",
              color: isActive ? "green" : "red",
              cursor: "pointer",
              outline: "none",
              transition:
                "border-color 0.2s, background-color 0.2s, color 0.2s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
            onBlur={(e) =>
              (e.target.style.borderColor = isActive ? "green" : "red")
            }
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        );
      },
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      editable: true,
    },

    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      editable: true,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 140,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: 130,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div>
      <PageTitle
        title="Sub-Vendors List - Manage & Track Vendor Accounts"
        description="View and manage the list of sub-vendors registered under the main vendors. Track their details, permissions, and assigned tasks efficiently."
        keywords="test series, sub-vendors list, manage sub-vendors, vendor hierarchy, vendor management, sub-vendor accounts, vendor tracking, test series sub-vendors, institute sub-vendors"
      />
      <Box>
        <Typography
          variant="h6"
          sx={{
            pb: 2,
            fontFamily: "Poppins, sans-serif",
            color: "#555",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          All Sub-Vendors List
        </Typography>

        <Paper sx={{ bgcolor: "white", mt: 3 }}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                // Style for checkbox in header
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              rows={vendors.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              // rows={vendors}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
              // checkboxSelection
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default EditorVendorList;
