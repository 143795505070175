import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Container, Box } from "@mui/material";
import PageTitle from "../Components/PageTitle/PageTitle";

const Instructions = ({ examData, setView, question }) => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle 
      title="Test Series Online Exam Instructions - Important Guidelines Before You Begin" 
      description="Get detailed instructions for your online exam, including time limits, navigation rules, passing criteria, and submission guidelines. Ensure a smooth exam experience by following these essential steps."
      keywords="test series, online exam, exam instructions, test guidelines, exam rules, passing marks, total marks, exam navigation, start exam, time limit, online test rules"      
      />
      <Container maxWidth="md" sx={{mt:{ xs: 6, sm:6, md: 6, lg: 10 },mb:8, }} >
        <Box mt={5} mb={10} textAlign="center" >
          <Typography variant="h4" gutterBottom>
            Instructions
          </Typography>
          <ul style={{ listStyleType: 'circle', paddingLeft: '20px', textAlign: 'left'}}>
            <li>
              <Typography sx={{marginTop: "20px" }}>
                Complete the exam within the allotted time of {examData?.duration} minutes.
              </Typography>
            </li>
            <li>
              <Typography sx={{marginTop: "12px" }}>
                The exam will be automatically submitted after the time limit ({examData?.duration} minutes) elapses.
              </Typography>
            </li>
            <li>
              <Typography sx={{marginTop: "12px" }}>
                Once submitted, your answers cannot be changed or modified.
              </Typography>
            </li>
            <li>
              <Typography sx={{marginTop: "12px" }}>
                Avoid refreshing the page during the exam as it may lead to loss of progress.
              </Typography>
            </li>
            <li>
              <Typography sx={{marginTop: "12px" }}> 
                Use the <strong>"Previous"</strong> and <strong>"Next"</strong> buttons to navigate between questions.
              </Typography>
            </li>
            <li>
              <Typography sx={{marginTop: "12px" }}>
                The exam consists of a total of <strong>{question}</strong> questions.
              </Typography>
            </li>
            <li>
              <Typography sx={{marginTop: "12px" }}>
                The total marks for the exam are <strong>{examData?.totalMarks}</strong>.
              </Typography>
            </li>
            <li>
              <Typography sx={{marginTop: "12px" }}>
                To pass the exam, you need to score a minimum of <strong>{examData?.passingMarks}</strong> marks.
              </Typography>
            </li>
          </ul>

          <Box mt={5} sx={{
            pb:10
          }}>
            <Button
            sx={{mb:3}}
              variant="outlined"
              onClick={() => navigate("/exams")}
            >
              Close
            </Button>
            <Button
            sx={{mb:3}}
              variant="contained"
              onClick={() => {
                setView("questions");
              }}
              style={{ marginLeft: '20px' }}
            >
              Start Exam
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Instructions;

