import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Container,
  Card,
  Box,
  Grid,
  Typography,
  Button,
  CardActions,
  CardContent,
  TextField,
  Tooltip,
  Badge,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "../axiosInstance";
import Footer from "../Components/Footer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import moment from "moment";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import useInstituteUser from "../Components/GetInstituteUser";

const Notes = () => {
  const studentCourse = useInstituteUser();
  const [notes, setNotes] = useState([]);
  const [search, setSearch] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const currentDate = moment().startOf("day");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [instituteName, setInstituteName] = useState("");

  // Get All note Data
  const getAllNotes = async () => {
    try {
      const response = await axios.post("/api/v1/common/get-all-notes", {
        studentCourse,
      });
      const noteData = response?.data?.data || [];
      const updatedExamData = noteData.map((note) => {
        const matchedCourse = studentCourse.find(
          (course) => course.studentCourseId === note.instituteCourseId
        );
        return {
          ...note,
          instituteCourseId:
            matchedCourse?.studentCourseName || note.instituteCourseId,
        };
      });
      setNotes(updatedExamData);
      setSearch(updatedExamData);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    if (studentCourse) {
      getAllNotes(studentCourse);
    }
  }, [studentCourse]);

  const openDownloadModal = (note) => {
    setSelectedNote(note);
    setOpenModal(true);
  };

  const closeDownloadModal = () => {
    setOpenModal(false);
    setSelectedNote(null);
  };

  // Handle the download after confirmation
  const handleDownload = async () => {
    if (!selectedNote?.url || !selectedNote?.subject_name) {
      message.error("Invalid URL or subject name");
      return;
    }

    let fileName =
      selectedNote.subject_name || selectedNote.filename || "download";
    if (!fileName.toLowerCase().endsWith(".pdf")) {
      fileName += ".pdf";
    }

    try {
      const response = await fetch(selectedNote.url);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      closeDownloadModal();
    } catch (error) {
      message.error("Failed to download PDF:", error);
    }
  };

  // Search function for filtering exams
  const searchAllVideo = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  // Filter exams based on the course and search query
  const getFilteredNote = () => {
    return notes.filter((note) => {
      const matchesSearch =
        note.subject_name.toLowerCase().includes(searchQuery) ||
        note.subject_type.toLowerCase().includes(searchQuery);

      const matchesCourse = selectedCourse
        ? note.instituteCourseId === selectedCourse
        : true;

      return matchesSearch && matchesCourse;
    });
  };

  // Handle course selection change
  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const courses = Array.isArray(studentCourse) ? studentCourse : [];

  useEffect(() => {
    const storedData = localStorage.getItem("instituteInfo");
    if (storedData) {
      const instituteInfoData = JSON.parse(storedData);
      setInstituteName(instituteInfoData[0]?.instituteName || "");
    }
  }, []);

  return (
    <>
      <Navbar />
      <PageTitle
        title="Notes - Scalable & Efficient Node Management"
        description="Access high-quality notes curated by experts to help you excel in your studies. Download now!"
        keywords="test series, study notes, PDF notes, course materials, exam preparation, institute notes"
      />

      <Container sx={{ pt: 3, mt: { xs: 12, sm: 12, md: 12, lg: 14 } }}>
        <Grid container spacing={3}>
          {/* Search Input */}
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              sx={{
                bgcolor: "white",
                borderRadius: "10px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputLabel-root": {
                  fontWeight: "bold",
                  color: "#1976d2",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "8px",
                },
              }}
              fullWidth
              id="text"
              name="text"
              placeholder="Search by Subject Name and Type"
              value={searchQuery}
              onChange={searchAllVideo}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "#1976d2" }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          {/* Course Name Dropdown */}
          <Grid item xs={12} sm={4} md={4}>
            <FormControl
              fullWidth
              sx={{
                mb: 3,
                bgcolor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <InputLabel sx={{ fontWeight: "bold", color: "#1976d2" }}>
                Course Name
              </InputLabel>
              <Select
                value={selectedCourse}
                onChange={handleCourseChange}
                label="Course Name"
                sx={{
                  bgcolor: "white",
                  borderRadius: "8px",
                  "& .MuiSelect-icon": {
                    color: "#1976d2",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1976d2",
                  },
                }}
              >
                <MenuItem value="">
                  <em>All Courses</em>
                </MenuItem>
                {courses?.length > 0 ? (
                  courses.map((course) => (
                    <MenuItem
                      key={course.studentCourseId}
                      value={course.studentCourseName}
                    >
                      {course.studentCourseName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No courses available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
        <Box
          sx={{
            pt: 5,
            pb: 5,
            minHeight: "83vh",
            mr: { md: 12, xs: 2 },
            ml: { md: 12, xs: 2 },
          }}
        >
          <Grid container spacing={3}>
            {getFilteredNote()?.length > 0 ? (
              getFilteredNote()?.map((note, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Badge
                    badgeContent="Upcoming"
                    color="primary"
                    invisible={moment(note.startDate).isSameOrBefore(
                      currentDate
                    )}
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Card
                      sx={{
                        transition: "0.3s !important",
                        "&:hover": { transform: "scale(1.05)" },
                        borderRadius: "15px",
                        boxShadow:
                          "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                        padding: "16px",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardContent>
                        <Box display="flex" alignItems="center" mb={2}>
                          <PictureAsPdfIcon fontSize="large" color="error" />
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            className="courses_title"
                            sx={{
                              marginLeft: "10px",
                              fontSize: { xs: "18px", sm: "20px" },
                              marginBottom: "12px",
                              fontWeight: "bold",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              color: "#2C3E50",
                              // color: "#1E88E5",
                            }}
                          >
                            {note.subject_name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="courses_desc"
                          sx={{
                            mt: 1,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            fontSize: "15px",
                            letterSpacing: ".1rem",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <strong>Notes Type:</strong> {note.subject_type}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="courses_desc"
                          sx={{
                            mt: 1,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            fontSize: "15px",
                            letterSpacing: ".1rem",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <strong>File Size:</strong>{" "}
                          {note.fileSize
                            ? `${note.fileSize.toFixed(2)} MB`
                            : "N/A"}
                        </Typography>
                        {moment(note.startDate).isSameOrBefore(currentDate) ? (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="courses_desc"
                            sx={{
                              mt: 1,
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              overflow: "hidden",
                              fontSize: "15px",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <strong>Uploaded on:</strong>{" "}
                            {new Date(note?.startDate)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })
                              .replace(/ /g, "-")}
                            {/* {moment(note.startDate).format("MMMM Do YYYY")} */}
                          </Typography>
                        ) : (
                          <Typography
                            className="courses_desc"
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              mt: 1,
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#34495E",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <strong>Start Date:</strong>{" "}
                            {new Date(note?.startDate)
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })
                              .replace(/ /g, "-")}
                            {/* {moment(note?.startDate).format("MMMM Do YYYY")} */}
                          </Typography>
                        )}
                        <Typography
                          className="courses_desc"
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            mt: 1,
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#34495E",
                            letterSpacing: ".1rem",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          <strong>End Date:</strong>{" "}
                          {new Date(note?.endDate)
                            .toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })
                            .replace(/ /g, "-")}
                          {/* {moment(note?.endDate).format("MMMM Do YYYY")} */}
                        </Typography>
                      </CardContent>

                      <CardActions sx={{ justifyContent: "center", mb: 2 }}>
                        {moment(note.startDate).isSameOrBefore(currentDate) ? (
                          <Button
                            // onClick={() => handleDownload(note)}
                            onClick={() => openDownloadModal(note)}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            sx={{
                              mt: 1,
                              borderRadius: "50px",
                              textTransform: "none",
                              fontFamily: "Poppins, sans-serif",
                              letterSpacing: ".1rem",
                            }}
                          >
                            Download Notes
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            disabled
                            sx={{
                              mt: 1,
                              borderRadius: "50px",
                              textTransform: "none",
                              fontFamily: "Poppins, sans-serif",
                              letterSpacing: ".1rem",
                            }}
                          >
                            Upcoming Notes
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Badge>
                </Grid>
              ))
            ) : (
              <Box
                style={{
                  color: "#878787",
                  margin: "30px 80px",
                  fontSize: 18,
                  letterSpacing: "1px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                No Available Notes!!
              </Box>
            )}
          </Grid>
        </Box>
      </Box>

      {/* Download Confirmation Modal */}
      <Dialog open={openModal} onClose={closeDownloadModal}>
        <DialogTitle>Confirm Download</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to download the note for{" "}
            <strong>{selectedNote?.subject_name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDownloadModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownload} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </>
  );
};

export default Notes;
