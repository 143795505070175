/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from "@mui/material";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import PageTitle from "../PageTitle/PageTitle";
import axios from "../../axiosInstance";

const PaymentPending = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleRetry = () => {
    navigate("/testseries/plan");
  };

  const handleBackToHome = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!orderId) {
      console.error("Order ID is missing!");
      return;
    }

    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `/api/get-payment-details?orderId=${orderId}`
        );
        setPaymentDetails(response.data.payment);
        setLoading(false);
      } catch (err) {
        message.error(err.message || "Error fetching payment details");
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [orderId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const { transactionID, amount, createdAt, status, name, requestedPlan } =
    paymentDetails || {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(",", "");
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  return (
   <>
    <PageTitle 
    title="Payment Pending - Complete Your Test Series Purchase"
    description="Your payment for the test series is currently pending. Please check your transaction status or retry the payment to gain full access to your exams and courses."
    keywords="test series, payment pending, test series payment, incomplete transaction, retry payment, online test purchase, pending payment issue, exam access pending"
    />
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
        padding: 2,
      }}
    >
      <Card sx={{ maxWidth: 500, padding: 3, textAlign: "center" }}>
        <PendingActionsIcon sx={{ fontSize: 80, color: "red", marginBottom: 2 }} />
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Payment Failed
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Unfortunately, {name} your payment was not successful.
        </Typography>

        <CardContent>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Transaction Details
          </Typography>
          <Grid container spacing={2} textAlign="left">
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Transaction ID:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{transactionID}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Amount:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{"\u20B9"}{amount}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Date:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{formatDate(createdAt)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Time:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{formatTime(createdAt)}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Payment Status:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{status}</Typography>
            </Grid>
            <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            Requested Plan:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">{requestedPlan}</Typography>
                        </Grid>
          </Grid>
        </CardContent>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            marginTop: 3,
          }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={handleRetry}
            sx={{ textTransform: "none" }}
          >
            Retry Payment
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBackToHome}
            sx={{ textTransform: "none" }}
          >
            Back to Home
          </Button>
        </Box>
      </Card>
    </Box>
   </>
  );
};

export default PaymentPending;
