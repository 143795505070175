import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Link as MUILink,
  Button,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import { Link } from "react-router-dom";
import PageTitle from "../Components/PageTitle/PageTitle";
import { useSelector } from "react-redux";
import { message } from "antd";
import axios from "../axiosInstance";

const ContactSupport = () => {
  const { user } = useSelector((state) => state.user);
  const [vendorMsg, setVendorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle Vendor Query Details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      vendorMsg,
      vendorName: user?.name,
      vendorEmail: user?.email,
      vendorPhone: user?.phone,
      vendorInstituteId: user?.instituteId,
      vendorId: user?._id,
    };

    try {
      const response = await axios.post(
        "/api/v1/query/admin-add-query-contactSupport",
        data
      );
      if (response.data.success === true) {
        message.success(response.data.message);
        setVendorMsg("");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }finally {
      setLoading(false); 
    }
  };

  return (
    <>
      <PageTitle 
      title="Contact Support - Get Help & Assistance" 
      description="Need help? Contact our support team for assistance with exams, courses, payments, and more. We are here to help you 24/7."
      keywords="test series, contact support, help center, customer support, test series help, exam support, technical assistance, student support, institute help"
      />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Contact Support
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Contact Support</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mt: 5,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{
            mb: 2,
            mt: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#34495E",
            letterSpacing: "1.5px",
            lineHeight: 1.5,
          }}
        >
          We're Here to Help! Reach Out Anytime
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Message"
            name="message"
            value={vendorMsg}
            placeholder="Write Your Query Here..."
            onChange={(e) => setVendorMsg(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            sx={{ mt: 3 }}
          />
          <div
            className="submit-section"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              color="primary"
              className="courses_desc"
              sx={{
                mt: 3,
                mb: 3,
                borderRadius: "5px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              
               {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </div>
        </form>
      </Box>
    </>
  );
};

export default ContactSupport;
