import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { message } from "antd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useInstituteUser from "../Components/GetInstituteUser";
import axios from "../axiosInstance";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import PageTitle from "../Components/PageTitle/PageTitle";
import "../stylesheets/startVideoExam.css";

const StartVideoExam = () => {
  const params = useParams();
  const { videoId } = params;
  const { user } = useSelector((state) => state.user);
  const studentCourse = useInstituteUser();
  const [videoData, setVideoData] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [showQuestionPopup, setShowQuestionPopup] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showRedButton, setShowRedButton] = useState(false);
  const [showResultPopup, setShowResultPopup] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [fetchResults, setFetchResults] = useState(false);
  const [instituteId, setInstituteId] = useState("");
  const [instituteCourseId, setInstituteCourseId] = useState("");
  const [studentQuestion, setStudentQuestion] = useState("");
  const [allStudentQuestion, setAllStudentQuestion] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = user?._id;
  const [instituteName, setInstituteName] = useState("");
  

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const res = await axios.get(
          `/api/v1/videos/exam/get-video-by/${videoId}`
        );
        setVideoData(res.data);
        setInstituteId(res.data.instituteId);
        setInstituteCourseId(res.data.instituteCourseId);
      } catch (error) {
        message.error("Error loading video data");
      }
    };
    fetchVideo();
  }, [videoId]);

  // Fetch the results after the video ends
  useEffect(() => {
    if (fetchResults) {
      const fetchResults = async () => {
        try {
          const res = await axios.post(
            `/api/v1/videos/exam/report/get-user-report`,
            {
              userId,
              videoId,
              instituteId,
              instituteCourseId,
            }
          );
          if (res.data.result && typeof res.data.result === "object") {
            setResultData([res.data.result]);
          } else {
            setResultData([]);
          }
          setShowResultPopup(true);
        } catch (error) {
          console.error("Error fetching results:", error);
          message.error("Error fetching results");
        }
      };
      fetchResults();
      setFetchResults(false);
    }
  }, [fetchResults, videoId, userId]);

  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);
    const questionToAsk = videoData?.questions.find(
      (question) =>
        state.playedSeconds >= question.time &&
        !userResponses.some((response) => response.questionId === question._id)
    );
    if (questionToAsk && !showRedButton) {
      setShowRedButton(true);
      setCurrentQuestion(questionToAsk);
      setIsVideoPaused(true);
    }
  };

  const handleRedButtonClick = () => {
    setShowRedButton(false);
    setShowQuestionPopup(true);
    setIsVideoPaused(true);
  };

  const handleAnswer = (answer) => {
    const isMultipleSelect = currentQuestion.correctAnswers.length > 1;
    if (isMultipleSelect) {
      setSelectedAnswer((prev) =>
        prev.includes(answer)
          ? prev.filter((item) => item !== answer)
          : [...prev, answer]
      );
    } else {
      setSelectedAnswer(answer);
    }
  };

  // Submit User Answer Video Report
  const submitAnswer = (answer) => {
    const isMultipleSelect = currentQuestion.correctAnswers.length > 1;
    if (
      (isMultipleSelect && selectedAnswer.length === 0) ||
      (!isMultipleSelect && !selectedAnswer)
    ) {
      message.warning("Please select an answer");
      return;
    }

    const correctAnswersText = currentQuestion.correctAnswers.map((index) =>
      currentQuestion.options[index].trim()
    );

    const isCorrect = isMultipleSelect
      ? selectedAnswer.every((answer) => correctAnswersText.includes(answer)) &&
        selectedAnswer.length === correctAnswersText.length
      : correctAnswersText.includes(selectedAnswer);

    const newResponse = {
      questionId: currentQuestion._id,
      options: currentQuestion.options.map((option) => option.trim()),
      selectedAnswer: isMultipleSelect ? selectedAnswer : [selectedAnswer],
      correctAnswer: correctAnswersText,
      isCorrect,
    };

    setUserResponses((prev) => [...prev, newResponse]);
    setShowQuestionPopup(false);
    setIsVideoPaused(false);
    setSelectedAnswer(isMultipleSelect ? [] : "");
  };

  // Add User Video Report
  const handleVideoEnd = async () => {
    try {
      const res = await axios.post(
        "/api/v1/videos/exam/report/add-user-video-report",
        {
          videoId,
          userId,
          answers: userResponses,
          questionTopics: videoData?.questionTopics,
          instituteCourseId: videoData?.instituteCourseId,
          instituteId: videoData?.instituteId,
          vendorId: videoData?.vendorId,
        }
      );
      if (Array.isArray(res.data.response)) {
        setResultData(res.data.response);
      } else {
        setResultData([]);
      }
      setFetchResults(true);
    } catch (error) {
      message.error(error.response.data.message || "Failed to save responses.");
    }
  };

  // Handle Submit Question
  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    if (!studentQuestion.trim()) {
      message.error("Please enter a Question Name.");
      setLoading(false);
      return;
    }
    const questionData = {
      videoId,
      question: studentQuestion,
      userId: user?._id,
      instituteCourseId,
      instituteId,
    };
    try {
      const response = await axios.post(
        "/api/v1/question/video/exam/add-question-video-exam",
        questionData
      );
      if (response.data.success === true) {
        message.success(response?.data.message);
        setStudentQuestion("")
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  // Get all student question
  const getStudentQuestions = async () => {
    try {
      const response = await axios.post(
        `/api/v1/question/video/exam/get-question-video-exam`,
        {
          // studentCourse,
          videoId
        }
      );
      if (response?.data?.success === true) {
        setAllStudentQuestion(response?.data?.studentQuestion);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getStudentQuestions();
    }, 1000);

    return () => clearTimeout(timer);
  }, [studentCourse]);

   useEffect(() => {
      const storedData = localStorage.getItem("instituteInfo");
      if (storedData) {
        const instituteInfoData = JSON.parse(storedData);
        setInstituteName(instituteInfoData[0]?.instituteName || "");
      }
    }, []);

  return (
    <div>
      <Navbar />
      <PageTitle
      title="Start Video Exam - Interactive Learning & Assessment" 
      description= "Engage in an interactive video-based exam experience with real-time questions and instant feedback. Test your knowledge, track progress, and enhance learning through video assessments"
      Keywords="test series, test series video exam, video exam, interactive learning, online assessment, video-based quiz, real-time exam, e-learning, student video test, online video questions, educational video assessment, video quiz platform"
      />
      <Box
        sx={{
          bgcolor: "#fafafa",
          minHeight: "100vh",
          pt: 5,
          pb: 5,
          pl: 3,
          pr: 3,
          mt: { xs: 10, sm: 10, md: 10, lg: 10 },
        }}
      >
        <ReactPlayer
          url={videoData?.videoUrl}
          controls={!showRedButton}
          onProgress={handleProgress}
          playing={!isVideoPaused}
          width="100%"
          height="640px"
          onEnded={handleVideoEnd}
          style={showRedButton ? { zIndex: 1, position: "relative" } : {}}
          config={{
            youtube: {
              playerVars: {
                controls: showRedButton ? 0 : 1,
                fs: 0,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
                iv_load_policy: 3,
                disablekb: 1,
              },
            },
          }}
        />

        {showRedButton && (
          <button className="red-button" onClick={handleRedButtonClick}>
            Answer Question
          </button>
        )}
        {showQuestionPopup && (
          <div className="question-popup">
            <h3
              dangerouslySetInnerHTML={{
                __html: currentQuestion?.questionName,
              }}
            ></h3>
            <div className="options-container">
              {currentQuestion?.options.map((option, i) => (
                <button
                  key={i}
                  onClick={() => handleAnswer(option)}
                  className={`option-button ${
                    selectedAnswer.includes(option) ? "selected" : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: option }}
                ></button>
              ))}
            </div>
            <button className="submit-answer-button" onClick={submitAnswer}>
              Submit Answer
            </button>
          </div>
        )}
        {showResultPopup && (
          <div className="result-popup">
            <h2>Results</h2>
            {resultData && resultData.length > 0 ? (
              resultData.map((result, i) => (
                <div key={i} className="result-item">
                  <div className="result-item-row">
                    <strong>Correct Answers:</strong> {result.correctAnswers}
                  </div>
                  <div className="result-item-row">
                    <strong>Incorrect Answers:</strong>{" "}
                    {result.incorrectAnswers}
                  </div>
                  <div className="result-item-row">
                    <strong>Percentage:</strong> {result.percentage}%
                  </div>
                  <div className="result-item-row">
                    <strong>Total Questions:</strong> {result.totalQuestions}
                  </div>
                </div>
              ))
            ) : (
              <div>No results available.</div>
            )}
            <button
              className="close-result-button"
              onClick={() => setShowResultPopup(false)}
            >
              Close
            </button>
          </div>
        )}
        <Box sx={{  pt: 5, pb: 5 }}>
          <Typography
            sx={{
              mt: 1,
              display: "flex",
              fontSize: "18px",
              letterSpacing: ".1rem",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Ask your question here{" "}
          </Typography>
          <ReactQuill
            id="question"
            label="Question"
            name="question"
            placeholder="Ask your question here..."
            value={studentQuestion}
            onChange={setStudentQuestion}
            style={{
              marginTop: "18px",
            }}
          />

          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="courses_desc"
            sx={{
              mb: 5,
              mt: 2,
              borderRadius: "5px",
              textTransform: "none",
              fontFamily: "Poppins, sans-serif",
              letterSpacing: ".1rem",
            }}
            onClick={(e) => handleSubmitQuestion(e)}
          >
            Submit Question
          </Button>
          <Box
            sx={{
              mb: 5,
            }}
          >
             All Questions and Their Answers ({allStudentQuestion?.length ?? 0})
          </Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            allStudentQuestion?.map((questionData, index) => (
              <Accordion key={questionData?._id} className="question-form">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="courses_desc"
                    sx={{
                      mt: 1,
                      display: "flex",
                      fontSize: "15px",
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                      whiteSpace: "pre-line", 
                      color: questionData?.correctAnswers?.length > 1 ? "green" : "black", // Color will be green if length > 1
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `Question ${index + 1} :- ${questionData?.question}`,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {questionData?.correctAnswers?.length > 0 ? (
                    <span
                      style={{
                        display: "flex",
                        fontSize: "15px",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Answer: - {" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: questionData?.correctAnswers,
                        }}
                        style={{
                          fontSize: "15px",
                          letterSpacing: ".1rem",
                          fontFamily: "Poppins, sans-serif",
                          marginLeft: "4px",
                        }}
                      />
                    </span>
                  ) : (
                    <span
                      style={{
                        fontSize: "15px",
                        letterSpacing: ".1rem",
                        fontFamily: "Poppins, sans-serif",
                        color: "#999", 
                        fontStyle: "italic",
                      }}
                    >
                      Answer: - No answer available yet. Please check back
                      later.
                    </span>
                  )}
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Box>
      </Box>

      <Footer />
    </div>
  );
};

export default StartVideoExam;
