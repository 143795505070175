import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  IconButton,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PageTitle from "../../Components/PageTitle/PageTitle";
import axios from "../../axiosInstance";

const StudentQuestion = () => {
  const { user } = useSelector((state) => state.user);
  const [studentQuestion, setStudentQuestion] = useState([]);
  const [filteredQuestion, setFilteredQuestion] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Get all video exams
  const getAllStudentQuestions = async () => {
    try {
      const response = await axios.get(
        `/api/v1/question/video/exam/admin-get-all-questions-videoExam`
      );
      if (response?.data?.success === true) {
        setStudentQuestion(response?.data?.data);
        setFilteredQuestion(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllStudentQuestions();
  }, []);

  useEffect(() => {
    let filtered = studentQuestion;

    if (searchQuery) {
      filtered = filtered.filter((question) => {
        const questionText =
          question.questions?.length > 0
            ? question.questions[0]?.question?.toLowerCase()
            : "";
        const studentEmail = question.userId?.studentEmail?.toLowerCase() || "";

        return (
          questionText.includes(searchQuery.toLowerCase()) ||
          studentEmail.includes(searchQuery.toLowerCase())
        );
      });
    }

    setFilteredQuestion(filtered);
  }, [searchQuery, studentQuestion]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Delete Exam
  const { confirm } = Modal;
  const deleteStudentQuestion = async (e, id) => {
    confirm({
      title: "Are you sure you want to delete this Student Question?",
      content:
        "This action will permanently delete the student question. Are you sure you want to continue?",
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/question/video/exam/admin-delete-question-videoExam",
            {
              data: { id },
            }
          );
          if (data.success === true) {
            message.success(data.message);
            getAllStudentQuestions();
          } else {
            message.error(data.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message ||
              "Failed to delete student question."
          );
        }
      },
      onCancel() {
        console.log("Delete action canceled.");
      },
    });
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
  
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteStudentQuestion(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <Link
            to={
              value.row.correctAnswers
                ? `/admin/edit/student/question/${value?.row?._id}`
                : `/admin/edit/student/question/${value?.row?._id}`
            }
          >
            <IconButton aria-label={value.row.correctAnswers ? "view" : "edit"}>
              {value.row.correctAnswers ? (
                <>
                <VisibilityIcon sx={{ color: "#1976d2" }} />   
                {/* / <EditIcon sx={{ color: "#1976d2" }} /> */}
                </>
              ) : (
                <AddCircleOutlineIcon sx={{ color: "#1976d2" }} />
              )}
            </IconButton>
          </Link>
        </Box>
      ),
    },

    {
      field: "correctAnswers",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <span style={{ color: params.row.correctAnswers ? 'green' : 'red' }}>
          {params.row.correctAnswers ? 'Completed' : 'Pending'}
        </span>
      ),
    },
    {
      field: "question",
      headerName: "Question Name",
      width: 220,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textAlign: "center",
            marginTop: "10px",
          }}
          dangerouslySetInnerHTML={{ __html: params.row.question }}
        />
      ),
    },

    {
      field: "questionTopics",
      headerName: "Question Topics",
      width: 220,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textAlign: "center",
            marginTop: "10px",
          }}
          dangerouslySetInnerHTML={{ __html: params.row?.videoId?.questionTopics }}
        />
      ),
      // valueGetter: (params) => params.row?.videoId?.questionTopics || "N/A",
    },

    {
      field: "videoUrl",
      headerName: "Video Url",
      width: 180,
      valueGetter: (params) => params.row?.videoId?.videoUrl || "N/A",
      renderCell: (params) => {
        const url = params.row?.videoId?.videoUrl;
        return url ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Button
              variant="outlined"
              color="primary"
              className="courses_desc"
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: ".1rem",
              }}
            >
              Watch Video
            </Button>
          </a>
        ) : (
          "N/A"
        );
      },
    },

    {
      field: "instituteCourseId",
      headerName: "Course Name",
      width: 150,
      valueGetter: (params) =>
        params.row?.instituteCourseId?.courseName || "General",
    },

    {
      field: "userId",
      headerName: "Student Email",
      width: 230,
      valueGetter: (params) => params.row?.userId?.studentEmail || "N/A",
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      width: 120,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];
  // Conditionally add the instituteId column if user.role === 1
  if (user && user.role === 1) {
    columns.splice(2, 0, {
      field: "instituteId",
      headerName: "Institute Id",
      width: 140,
      editable: true,
    });
  }

  return (
    <>
      <PageTitle title="Student Question List - Video Exam Questions & Answers" 
      description="View all questions from the interactive video exams. Track your responses, review video-based assessments, and enhance your learning through interactive questions."
      keywords="test series, student video exam questions, video-based test questions, interactive video quiz, video assessment questions, online video exam, e-learning video test, video MCQ list"
      />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        All Student Question List
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Student Question List</Typography>
      </Breadcrumbs>

      <Box
        style={{
          width: "auto",
          overflowY: "hidden",
          overflowX: "scroll",
        }}
      >
        {/* Search Input */}
        <Box sx={{ pb: 2, mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Question Name or Student Email"
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ color: "#1976d2" }} />
                </IconButton>
              ),
            }}
            sx={{
              mb: 3,
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
        </Box>

        <Paper sx={{ bgcolor: "white" }}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              rows={filteredQuestion.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default StudentQuestion;
