import React, { useState, useEffect } from "react";
import { message, Modal } from "antd";
import { Container, Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../axiosInstance";

const InstitutesCourseDetails = ({
  courseId,
  courseName,
  courseDesc,
  courseDate,
  courseFees,
  courseEndDate,
  instituteId,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [publicUser, setPublicUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  useEffect(() => {
    // Remove publicUser from localStorage if user state from Redux is available
    if (user && Object.keys(user).length > 0) {
      localStorage.removeItem("user");
      setPublicUser(null);
    }
  }, [user]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Check if the user is already enrolled in the course
  const isAlreadyEnrolled = user?.studentCourse?.some(
    (course) => course.studentCourseId === courseId && course.isActive === true
  );

  // handle Enroll Courses
  const handleEnroll = async (instituteId) => {
    // Check if the user is logged in by examining user and publicUser
    const isUserLoggedIn = user && Object.keys(user).length > 0;
    const isPublicUserLoggedIn = publicUser !== null;

    if (!isUserLoggedIn && !isPublicUserLoggedIn) {
      message.warning("Please log in to request in the course.");
      navigate("/login");
      return;
    }

    if (isAlreadyEnrolled) {
      message.success("You are already requested in this course.");
      return;
    }
    // Add the Public Student by enroll new institute course
    const enrollData = {
      userId: user?._id || publicUser?._id,
      requestUser: [
        {
          courseId: courseId,
          instituteId: instituteId,
        },
      ],
      name: user?.studentName || publicUser?.name || user.name, 
      email: user?.studentEmail || publicUser?.email || user.email,
      phone: user?.studentPhone || publicUser?.phone || user.phone,
    };
    try {
      const { data } = await axios.post(
        "/api/v1/public/user/add-public-user-enroll-course",
        enrollData
      );
      if (data.success === true) {
        message.success(data.message);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          sx={{
            mt: 2,
            borderRadius: "50px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
          onClick={showModal}
        >
          More Details
        </Button>
        <Modal
          title={`${courseName} Course Details `}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
          width={1300}
        >
          <Container component="form" className="form_style border-style">
            <Box sx={{ background: "#f7fafd" }}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  margin: "0 auto",
                  padding: "20px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "700",
                    fontSize: "28px",
                    marginBottom: "10px",
                    color: "#2C3E50",
                    fontFamily: "Poppins, sans-serif",
                    letterSpacing: ".1rem",
                    textAlign: "center",
                  }}
                >
                  {courseName}
                </Typography>
                <Typography
                  className="vendor_about_description"
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    mt: 1,
                    fontSize: "15px",
                    lineHeight: 1.6,
                    // color: "#7F8C8D",
                    letterSpacing: ".1rem",
                    fontFamily: "Poppins, sans-serif",
                    textAlign: "start",
                  }}
                  dangerouslySetInnerHTML={{ __html: courseDesc }}
                >
                  {/* {courseDesc} */}
                </Typography>

                <Typography
                  className="courses_desc"
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    mt: 2,
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#34495E",
                    letterSpacing: ".1rem",
                    fontFamily: "Poppins, sans-serif",
                    textDecoration: isAlreadyEnrolled ? "line-through" : "none",
                  }}
                >
                  <strong>Course Fees </strong>
                  {courseFees}
                </Typography>
                <Typography
                  className="courses_desc"
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    mt: 2,
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#34495E",
                    letterSpacing: ".1rem",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <strong>Course Start Date: </strong>
                  {new Date(courseDate)
                    .toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                    .replace(/ /g, "-")}
                </Typography>

                {isAlreadyEnrolled && (
                  <Typography
                    className="courses_desc"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#e74c3c",
                      letterSpacing: ".1rem",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <strong>Course End Date: </strong>
                    {new Date(courseEndDate)
                      .toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/ /g, "-")}
                  </Typography>
                )}

                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={isAlreadyEnrolled}
                    sx={{
                      mt: 2,
                      borderRadius: "50px",
                      textTransform: "none",
                      fontFamily: "Poppins, sans-serif",
                      letterSpacing: ".1rem",
                    }}
                    onClick={() => handleEnroll(instituteId)} // Wrap in an arrow function to pass instituteId
                  >
                    {isAlreadyEnrolled
                      ? "Already assigned course"
                      : "Request Course"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Modal>
      </>
    </div>
  );
};

export default InstitutesCourseDetails;
