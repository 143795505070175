import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  IconButton,
  Typography,
  Breadcrumbs,
  Link as MUILink,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { message, Modal } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { useSelector } from "react-redux";

function TopRank() {
  const { user } = useSelector((state) => state.user);
  const [topRankData, setTopRankData] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    axios
      .get("/api/v1/reports/admin-get-top-rank-reports")
      .then((response) => {
        const data = response.data.data;

        // Sort by score in descending order
        const sortedData = data.sort((a, b) => {
          const aScore = a.result?.obtainedMarks || 0;
          const bScore = b.result?.obtainedMarks || 0;
          return bScore - aScore;
        });

        setTopRankData(sortedData);
        setFilteredReports(sortedData);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  }, []);

  // Function to export data to PDF with index column
  const exportPDF = () => {
    Modal.confirm({
      title: "Download Report",
      content: "Do you want to download the Top Rank Reports as a PDF?",
      okText: "Yes, Download",
      cancelText: "No, Cancel",
      onOk: () => {
        const doc = new jsPDF();
        const columns = [
          "#",
          "Student Name",
          "Student Email",
          "Exam Name",
          "Student Score",
        ];

        const rows = topRankData.map((item, index) => [
          index + 1,
          item.userName || "N/A",
          item.userEmail || "N/A",
          item.examName || "N/A",
          `${
            item.result?.obtainedMarks
              ? item.result.obtainedMarks.toFixed(2)
              : "0.00"
          } / ${item?.totalMarks || 0}`,
        ]);

        // Add title
        doc.text("Top Rank Reports", 14, 10);

        // Generate the PDF with autotable
        doc.autoTable({
          head: [columns],
          body: rows,
          startY: 20,
        });

        // Save the PDF
        doc.save("Top_Rank_Reports.pdf");
      },
      onCancel() {
        console.log("Download canceled.");
      },
    });
  };

  // Search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = topRankData.filter((report) => {
      return (
        report.examName.toLowerCase().includes(query) ||
        report.userEmail.toLowerCase().includes(query)
      );
    });
    setFilteredReports(filtered);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Slice the data for pagination
  const paginatedData = filteredReports.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <PageTitle
        title="Top Rank MCQ Exam Report - Student Performance & Analysis"
        description="View the top-ranked students in MCQ exams. Analyze performance, compare scores, and generate detailed reports for better insights into exam results."
        keywords="test series, test series top MCQ exam report, top MCQ exam report, MCQ test rankings, student performance analysis, best MCQ scores, online test results, exam leaderboard, MCQ exam insights"
      />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        All Exams Top Rank List
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <MUILink
          component={Link}
          to="/admin/exams"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Exams
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Top Rank Reports</Typography>
      </Breadcrumbs>

      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Exam Name or Student Email"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon sx={{ color: "#1976d2" }} />
              </IconButton>
            ),
          }}
          sx={{
            mb: 3,
            bgcolor: "white",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={exportPDF}
        startIcon={<DownloadIcon />}
        className="courses_desc"
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "5px",
          textTransform: "none",
          fontFamily: "Poppins, sans-serif",
          letterSpacing: ".1rem",
        }}
      >
        Export to PDF
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{ backgroundColor: "rgb(25, 118, 210)", color: "white" }}
          >
            <TableRow>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Top Rank
              </TableCell>
              {user && user?.role === 1 ? (
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Institute Id
                </TableCell>
              ) : null}
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Student Name
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Student Email
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Exam Name
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Student Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.length > 0 ? (
              paginatedData.map((item, index) => (
                <TableRow
                  key={item._id}
                  sx={{
                    "&:nth-of-type(even)": { bgcolor: "#f5f5f5" },
                    "&:nth-of-type(odd)": { bgcolor: "#ffffff" },
                    ...(index === 0 ? { backgroundColor: "#dbe3e8" } : {}),
                  }}
                >
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  {user && user?.role === 1 ? (
                    <TableCell>{item?.instituteId || "N/A"}</TableCell>
                  ) : null}
                  <TableCell>{item?.userName || "N/A"}</TableCell>
                  <TableCell>{item?.userEmail || "N/A"}</TableCell>
                  <TableCell>{item?.examName || "N/A"}</TableCell>
                  <TableCell>
                    {item.result?.obtainedMarks
                      ? item.result.obtainedMarks.toFixed(2)
                      : "0.00"}{" "}
                    / {item?.totalMarks || 0}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No reports found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* Table Pagination */}
        <TablePagination
          component="div"
          count={filteredReports.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </TableContainer>
    </>
  );
}

export default TopRank;
