/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  MenuItem,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactPlayer from "react-player";
import { message } from "antd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { getAllCourse } from "../../redux/actions/userAction";
import PageTitle from "../../Components/PageTitle/PageTitle";
import axios from "../../axiosInstance";
import "./AddVideoExam.css";

const AddVideoExam = () => {
  const { courses } = useSelector((state) => state.courseData);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [video, setVideo] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [questionTopics, setQuestionTopics] = useState("");
  const [questions, setQuestions] = useState([
    { time: 0, questionName: "", options: ["", "", "", ""], correctAnswer: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const [studentCourseId, setStudentCourseId] = useState("");
  const [studentCourse, setStudentCourse] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const videoStartDate = moment(startDate).format("MM/DD/YYYY");
  const videoEndDate = moment(endDate).format("MM/DD/YYYY");

  const handleVideoChange = (e) => {
    setVideo(e.target.files[0]);
    setVideoUrl("");
  };

  const handleVideoUrlChange = (e) => {
    setVideoUrl(e.target.value);
    setVideo(null);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  // Fetch all institute courses
  useEffect(() => {
    dispatch(getAllCourse());
  }, [dispatch]);

  useEffect(() => {
    if (courses) {
      setStudentCourse(courses);
    }
  }, [courses]);

  // Handle Institute Course
  const handleChangeStudentCourse = (event) => {
    const selectedCourseName = event.target.value;
    setStudentCourse(selectedCourseName);

    // Assign a fixed ID for "General Course"
    const generalCourseId = "81af3e57846a42aab058dc4d"; // UUID Generate
    
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setStudentCourseId(selectedCourse._id);
    } else if (selectedCourseName === "General") {
      setStudentCourseId(generalCourseId);
    }
  };

  const addQuestionAtTime = () => {
    setQuestions([
      ...questions,
      {
        time: currentTime,
        questionName: "",
        options: ["", "", "", ""],
        correctAnswer: "",
      },
    ]);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        time: 0,
        questionName: "",
        options: ["", "", "", ""],
        correctAnswer: "",
      },
    ]);
  };

  // Video and Question Upload Api call Function
  const handleSubmit = async () => {
    setLoading(true);

    // Validate video or video URL
    if (!video && !videoUrl) {
      message.error(
        "Please upload a video file or provide a YouTube video link."
      );
      setLoading(false);
      return;
    }

    // Validate Institute Course ID
    if (!studentCourseId || studentCourseId.trim() === "") {
      message.error("Please select a course for the institute.");
      setLoading(false);
      return;
    }

    // Validate question topics
    if (!questionTopics || questionTopics.trim().length === 0) {
      message.error("Please enter a valid question topic.");
      setLoading(false);
      return;
    }

    // Validate Start and End Date
    if (!startDate || !endDate) {
      message.error("Please select both start and end dates.");
      setLoading(false);
      return;
    }

    if (moment(startDate).isAfter(moment(endDate))) {
      message.error("End date cannot be before start date.");
      setLoading(false);
      return;
    }

    // Validate time
    if (!questions[0]?.time || questions[0].time <= 0) {
      message.error("Please specify a valid time limit for the question.");
      setLoading(false);
      return;
    }

    // Validate question name
    if (
      !questions[0]?.questionName ||
      questions[0].questionName.trim().length === 0
    ) {
      message.error("Please provide a question name.");
      setLoading(false);
      return;
    }

    // Validate options and correct answers
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];

      // Ensure there are options
      if (question.options.some((option) => option.trim().length === 0)) {
        message.error(`Please provide valid options for Question.`);
        setLoading(false);
        return;
      }

      // Ensure at least one correct answer is selected correctAnswer
      if (!question.correctAnswers || question.correctAnswers.length === 0) {
        message.error(
          `Please select at least one correct answer for Question.`
        );
        setLoading(false);
        return;
      }
    }

    const formData = new FormData();
    if (video) {
      formData.append("video", video);
    } else if (videoUrl) {
      formData.append("videoUrl", videoUrl);
    }
    formData.append("questionTopics", questionTopics);
    formData.append("questions", JSON.stringify(questions));
    formData.append("instituteId", user?.instituteId);
    formData.append("vendorId", user?._id);
    formData.append("instituteCourseId", studentCourseId);
    formData.append("startDate", videoStartDate);
    formData.append("endDate", videoEndDate);

    try {
      const response = await axios.post(
        "/api/v1/videos/exam/admin-upload-videoExam",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.data.success === true) {
        message.success("Video and questions uploaded successfully!");
        setLoading(false);
      }
      // Reset fields after successful submission
      setVideo(null);
      setVideoUrl("");
      setQuestionTopics("");
      setQuestions([]);
      setStudentCourseId("");
      setStartDate(null);
      setEndDate(null);
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  // Add an option to a question
  const addOption = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].options.push("");
    setQuestions(newQuestions);
  };

  // Remove an option from a question
  const removeOption = (index, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[index].options.splice(optionIndex, 1);
    setQuestions(newQuestions);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswerChange = (questionIndex, optionIndex, checked) => {
    // Add or remove the correct answer from the list
    const updatedQuestions = [...questions];
    const correctAnswers = updatedQuestions[questionIndex].correctAnswers || [];

    if (checked) {
      correctAnswers.push(optionIndex);
    } else {
      const indexToRemove = correctAnswers.indexOf(optionIndex);
      if (indexToRemove > -1) {
        correctAnswers.splice(indexToRemove, 1);
      }
    }

    updatedQuestions[questionIndex].correctAnswers = correctAnswers;
    setQuestions(updatedQuestions);
  };

  return (
    <>
      <PageTitle 
      title="Interactive Video Course Exam - Answer Questions During Videos" 
      description="Engage in interactive video course exams where questions appear during the video. Test your understanding with MCQ and subjective assessments while watching educational content."
      keywords="test series, interactive video exam, video course assessment, online learning test, video-based MCQ, real-time quiz, educational video test, video lesson assessment, test series video quiz"
      />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          mb: 3,
          fontFamily: "Poppins, sans-serif",
          color: "#2C3E50",
          letterSpacing: "2.5px",
          lineHeight: 1.8,
        }}
      >
        Add Video And Question
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 5,
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        }}
      >
        <MUILink
          component={Link}
          to="/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Dashboard
        </MUILink>
        <Typography sx={{ color: "primary.main" }}>Video Exam</Typography>
      </Breadcrumbs>
      {/* Video Upload Section */}
      <div className="upload-section">
        <label htmlFor="video-file" className="file-label">
          {video ? `Selected File: ${video.name}` : "Choose Video File"}
        </label>
        <input
          type="file"
          id="video-file"
          onChange={handleVideoChange}
          className="file-input"
        />
        <div className="divider">or</div>
        {/* YouTube URL Input */}
        <input
          type="text"
          className="input-field"
          placeholder="Enter YouTube URL"
          value={videoUrl}
          onChange={handleVideoUrlChange}
        />
      </div>
      {(video || videoUrl) && (
        <div className="video-preview">
          <ReactPlayer
            url={video ? URL.createObjectURL(video) : videoUrl}
            playing={isPlaying}
            onProgress={({ playedSeconds }) => setCurrentTime(playedSeconds)}
            controls
            width="100%"
          />
          <div className="current-time">
            Current Time: {Math.floor(currentTime)}s
          </div>
        </div>
      )}

      {/* Button to add a question at current video time */}
      {(video || videoUrl) && (
        <button onClick={addQuestionAtTime} className="add-question-button">
          Add Question at {Math.floor(currentTime)}
        </button>
      )}

      <div className="questions-section">
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "#555",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          Add Questions
        </Typography>

        <Grid container spacing={2} sx={{ mt: 2, mb: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{
                mt: 2,
                mb:2,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              select
              name="studentCourse"
              label="Institute Course"
              placeholder="Institute Course"
              value={studentCourse}
              onChange={handleChangeStudentCourse}
              fullWidth
              variant="outlined"
            >
              <MenuItem disabled>Select Course</MenuItem>
              <MenuItem key="generalCourse" value="General">
                General
              </MenuItem>
              {courses &&
                courses.map((course) => (
                  <MenuItem key={course._id} value={course.courseName}>
                    {course.courseName}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <ReactQuill
              theme="snow"
              value={questionTopics}
              onChange={setQuestionTopics}
              placeholder="Enter question topics name"
            />
          </Grid>
          <Grid item xs={12} className="Date">
            <Box
              sx={{
                mb: 3,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "10px",
                alignItems: "center",
              }}
            >
              {/* Start Date Picker */}
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="Start Date"
                selected={startDate}
                onChange={handleStartDateChange}
                minDate={new Date()}
                maxDate={endDate}
                required
                style={{ flex: 1 }}
              />

              {/* End Date Picker */}
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="End Date"
                selected={endDate}
                onChange={handleEndDateChange}
                minDate={startDate || new Date()}
                required
                style={{ flex: 1 }}
              />
            </Box>
          </Grid>
        </Grid>

        {questions.map((question, index) => (
          <Accordion key={index} className="question-form">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`question-content-${index}`}
              id={`question-header-${index}`}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <h3>Question {index + 1}</h3>
                <Button
                  onClick={() => removeQuestion(index)}
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  style={{ padding: "5px 10px" }}
                >
                  Remove Question
                </Button>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Time (seconds)"
                    variant="outlined"
                    value={question.time}
                    onChange={(e) =>
                      handleQuestionChange(index, "time", e.target.value)
                    }
                    sx={{
                      mt: 2,
                      mb:2,
                      "& .MuiInputBase-root": {
                        color: "text.secondary",
                      },
                      "& input[type='number']": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                      },
                      fieldset: { borderColor: "rgb(231, 235, 240)" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ReactQuill
                    theme="snow"
                    value={question.questionName}
                    onChange={(value) =>
                      handleQuestionChange(index, "questionName", value)
                    }
                    placeholder="Enter question name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {question.options.map((option, optionIndex) => (
                      <Grid item xs={12} sm={6} md={6} key={optionIndex}>
                        <ReactQuill
                          theme="snow"
                          value={option}
                          onChange={(value) =>
                            handleOptionChange(index, optionIndex, value)
                          }
                          placeholder={`Option ${optionIndex + 1}`}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={question.correctAnswers?.includes(
                                optionIndex
                              )}
                              onChange={(e) =>
                                handleCorrectAnswerChange(
                                  index,
                                  optionIndex,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="Correct Answer"
                          style={{ marginTop: "10px" }}
                        />
                        <Button
                          onClick={() => removeOption(index, optionIndex)}
                          style={{ marginTop: "10px", color: "#d32f2f" }}
                        >
                          Remove
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => addOption(index)}
                    style={{ marginTop: "10px" }}
                  >
                    Add Option
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          onClick={addQuestion}
          variant="contained"
          type="submit"
          color="primary"
          className="courses_desc"
          sx={{
            mt: 3,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Add Another Question
        </Button>
      </div>

      {/* Submit Button */}
      <div className="submit-section">
        <Button
          onClick={handleSubmit}
          // className={`submit-button ${loading ? "loading" : ""}`}
          disabled={loading}
          variant="contained"
          type="submit"
          color="primary"
          className="courses_desc"
          sx={{
            // mt: 3,
            mb: 3,
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          {loading ? "Uploading..." : "Upload Video and Questions"}
        </Button>
      </div>
    </>
  );
};

export default AddVideoExam;
