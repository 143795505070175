import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Button, TextField } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { message, Modal } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";
import EditVendor from "./EditVendor";
import ViewVendorPlan from "./ViewVendorPlan";
import EditVendorPlan from "./EditVendorPlan";

const AllVendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Get all vendors
  const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
      setFilteredVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    displayVendor();
  }, []);

  // Delete user
  const { confirm } = Modal;
  const deleteUser = async (e, id, role_name) => {
    confirm({
      title: `Are you sure you want to delete this ${
        role_name || "user"
      } account?`,
      content: `Once deleted, this ${
        role_name || "user"
      } account will be permanently removed.`,
      okText: "Yes, Delete",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.delete(
            "/api/v1/common/admin-delete-vendor",
            {
              data: { id },
            }
          );
          if (data?.success === true) {
            message.success(data.message);
            displayVendor();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to delete the account."
          );
        }
      },
      onCancel() {
        console.log("User deletion canceled");
      },
    });
  };

  // Toggle user's active status
  const toggleUserActiveStatus = async (id, isActive, role_name) => {
    const action = isActive ? "activate" : "deactivate";

    // Show the confirmation modal before proceeding
    confirm({
      title: `Are you sure you want to ${action} this ${
        role_name || "user"
      } account?`,
      content: `Once you ${action} this account, it will ${action}d.`,
      okText: `Yes, ${action}`,
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          const { data } = await axios.post(
            "/api/v1/auth/admin-active-delete-user-vendor",
            {
              id,
              isActive: isActive,
            }
          );
          if (data?.success === true) {
            message.success(data?.message);
            displayVendor();
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Failed to change status."
          );
        }
      },
      onCancel() {
        console.log("Status change canceled");
      },
    });
  };

const exportPDF = () => {
  // Show the confirmation modal
  showExportConfirmation();
};

// Modal confirmation logic
const showExportConfirmation = () => {
  Modal.confirm({
    title: "Confirm Download",
    content: "Do you want to download the All Vendors List as a PDF?",
    okText: "Yes, Download",
    cancelText: "No, Cancel",
    onOk: () => {
      const doc = new jsPDF();
      const columns = [
        "#",
        "Institute Id",
        "Vendor Name",
        "Vendor Email",
        "Vendor Phone",
        "Vendor Role",
        "Create Date",
      ];

      // Check for empty vendor data
      if (!vendors || vendors.length === 0) {
        message.error("No vendor data available to export.");
        return;
      }

      // Map vendors data to rows
      const rows = filteredVendors.map((item, index) => {
        const instituteId = item.instituteId || "N/A";
        const name = item.name || "N/A";
        const email = item.email || "N/A";
        const phone = item.phone || "N/A";
        const role = item.role_name || "N/A";
        const createdAt = item.createdAt
          ? moment(item.createdAt).format("MM/DD/YYYY")
          : "N/A";

        return [index + 1, instituteId, name, email, phone, role, createdAt];
      });

      // Add title
      doc.text("All Vendors List", 14, 10);

      // Generate the PDF with autotable
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
      });

      // Save the PDF
      doc.save("All_Vendors_List.pdf");
    },
    onCancel() {
      console.log("Download canceled");
    },
  });
};


  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter vendors based on search query
    const filtered = vendors.filter(
      (vendor) =>
        vendor.name.toLowerCase().includes(query) ||
        vendor.email.toLowerCase().includes(query)
    );
    setFilteredVendors(filtered);
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 10,
      sortable: false,
    },
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) =>
              deleteUser(e, value?.row?._id, value?.row?.role_name)
            }
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditVendor
            vendorId={value?.row?._id}
            refreshData={displayVendor}
            name={value?.row?.name}
            email={value?.row?.email}
            phone={value?.row?.phone}
          />
        </Box>
      ),
    },
    {
      field: "Vendor Plan",
      width: 100,
      renderCell: (value) => {
        const userId = value?.row?._id;

        return (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {userId && <ViewVendorPlan userId={userId} />}

            {userId && <EditVendorPlan userId={userId} />}
          </Box>
        );
      },
    },

    {
      field: "isActive",
      headerName: "Active Status",
      width: 170,
      renderCell: (params) => {
        const isActive = params?.row?.isActive === true;
        return (
          <select
            value={isActive.toString()}
            onChange={(e) =>
              toggleUserActiveStatus(
                params.row._id,
                e.target.value === "true",
                params?.row?.role_name
              )
            }
            style={{
              padding: "6px 12px",
              fontSize: "14px",
              borderRadius: "8px",
              border: `1px solid ${isActive ? "green" : "red"}`,
              backgroundColor: isActive ? "#e0ffe0" : "#ffe0e0",
              color: isActive ? "green" : "red",
              cursor: "pointer",
              outline: "none",
              transition:
                "border-color 0.2s, background-color 0.2s, color 0.2s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
            onBlur={(e) =>
              (e.target.style.borderColor = isActive ? "green" : "red")
            }
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        );
      },
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    },
    {
      field: "name",
      headerName: "Full Name",
      width: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 180,
      editable: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      editable: true,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 140,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: 130,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div>
      <PageTitle 
      title="Admin Vendors - Manage & View All Registered Vendors" 
      description="Access the complete list of vendors registered on the platform. Manage vendor accounts, track subscriptions, and oversee business partnerships efficiently."
      keywords="test series, admin vendors, vendor management, vendor list, registered vendors, business partners, vendor accounts, test series vendors, institute vendors"
      />
      <Box>
        <Typography
          variant="h6"
          sx={{
            pb: 3,
            fontFamily: "Poppins, sans-serif",
            color: "#555",
            letterSpacing: "2.5px",
            lineHeight: 1.8,
          }}
        >
          All Vendors List
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Vendor Name or Vendor Email"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon sx={{ color: "#1976d2" }} />
              </IconButton>
            ),
          }}
          sx={{
            mb: 3,
            bgcolor: "white",
            borderRadius: "20px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={exportPDF}
          startIcon={<DownloadIcon />}
          className="courses_desc"
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: "Poppins, sans-serif",
            letterSpacing: ".1rem",
          }}
        >
          Export To PDF
        </Button>

        <Paper sx={{ height: 420, width: "100%" }}>
          <DataGrid
            rows={filteredVendors.map((vendor, index) => ({
              id: vendor._id,
              rowNumber: index + 1,
              ...vendor,
            }))}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              // Style for header cells
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
                // fontSize: "13px",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default AllVendorList;
