import React from "react";
import { Helmet } from "react-helmet";

const PageTitle = ({ title, description, keywords }) => {
  // Structured data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "GatikAI Technologies",
    "url": `https://testseries.gatiktech.com`,
    "author": {
      "@type": "Organization",
      "name": "GatikAI Technologies"
    },
    "logo": "https://testseries.gatiktech.com/logo192.png",  
    "description": description,
    "publisher": {
      "@type": "Organization",
      "name": "GatikAI Technologies",
      "logo": {
        "@type": "ImageObject",
        "url": "https://testseries.gatiktech.com/logo192.png"
      }
    },
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Arg group, north avenue, 306, third floor, road no 9, Sikar Rd",
      "addressLocality": "Jaipur",
      "addressRegion": "Rajasthan",
      "postalCode": "302013",
      "addressCountry": "India"
    },
    "telephone": "+91-9680990993",  
    "sameAs": [ 
     "https://www.linkedin.com/company/gatikai-technologies/home",
     "https://www.facebook.com/people/GatikAi-Technologies/61553559459205/?mibextid=rS40aB7S9Ucbxw6v",
     "https://www.instagram.com/gatik_tech?igsh=emI4ZXg0OTE3bWVn&utm_source=qr",
    ]
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} /> 
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://testseries.gatiktech.com`} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://testseries.gatiktech.com/logo192.png" /> 
      <meta property="og:site_name" content="GatikAI Technologies" />
      
      {/* Schema.org structured data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default PageTitle;

